(function (window) {
	var privacyStub = {
		apiQueue: [], // Stores callbacks that are waiting to be processed
		//s: "DNS|US",
		/**
		 * Get the correct regulatory situation for the user
		 *
		 * @return  {string}  A string describing the applicable regulatory environment to use to understand the user's privacy eligibility and consent. The string is a pipe-delimited list of values. The first value is the user's privacy eligibility, and the second value is the applicable regulatory environment.
		 * @example "DNS|US"
		 */
		regs: function () {
			const geoInfoInternal = this.geoInfo; //.bind(this);
			const documentCookies = document.cookie;
			const localStorageValues =
				localStorage && localStorage.getItem("wapo_identity");
			let result = geoInfoInternal(documentCookies);
			if (!result && localStorageValues) {
				let data = JSON.parse(localStorageValues);
				let lsGeoState = data.geoState;
				let lsAddressState = data.addressState;
				const wapoIDState = geoInfoInternal(lsGeoState, true);
				const wapoIDAddress = geoInfoInternal(lsAddressState, true);
				if (wapoIDAddress || wapoIDState) {
					return wapoIDAddress || wapoIDState;
				}
			}
			// This test doesn't run for localStorage. It's only for cookies
			let eacCheck = /wp_ucp=[^;]*?\|?EAC:(0|1)/.test(documentCookies);
			if (eacCheck) {
				if (!result) {
					result = "DNS";
				}
				result += "|EAC";
			}
			return result;
		},
		handleApiCall: function (commandName, v, callback) {
			// If PrivacyAPI is not yet ready, enqueue the callback
			if (commandName == "GEO") {
				var result = this.regs();
				callback && callback(result);
				return result;
			}
			this.apiQueue.push([commandName, v, callback]);
		},

		/**
		 * Takes cookies and a check mode and returns the geo state
		 *
		 * @param   {string}  geoString    document.cookie value or other string
		 * @param   {true|null}  simpleMode Use simple mode for checking localStorage. Only for checking US States
		 *
		 * @return  {string}        A string that describes
		 * 							the user's privacy eligibility
		 * 							based on geo.
		 */
		geoInfo: (geoString, simpleMode) => {
			let result = "DNS|US|";
			geoString = geoString || "";
			let regexer = (pattern, testString) =>
				new RegExp(pattern).test(testString);
			if (!simpleMode) {
				let geoCookie = "wp_geo=";
				let optInString = "OPT_IN|";
				if (!regexer(geoCookie, geoString)) {
					return result;
				}
				if (regexer(geoCookie + "[^;]*?\\|EEA", geoString)) {
					return optInString + "EEA";
				}
				// CA|QC|||
				if (regexer(geoCookie + "CA\\|QC\\|", geoString)) {
					return optInString + "QC";
				}
				if (regexer(geoCookie + "CA\\|", geoString)) {
					// return optInString + "CAN";
				}
			}
			let stateRegex = "(CA|VA|CT|CO|UT|DE|IA|MT|NH|NJ|OR|TX|NE)";
			if (!simpleMode) {
				// equivalent to `new RegExp(/(?:US\|)((CA|VA|CT|CO|UT))/);`
				stateRegex = "(?:US\\|)" + stateRegex;
			}

			// Check if user is in opt out state
			let regexResult = new RegExp(stateRegex).exec(geoString);
			if (regexResult) {
				// || Check if the wp_ucp cookie exists and contains `EAC`
				// NOTE: the `|W` must always be at the end of the string for regexs to check it effectively.
				return (
					result +
					(regexResult[1] == "CA"
						? regexResult[1] + "|W"
						: regexResult[1])
				);
			}
			return false;
		},

		init: function () {
			// Regularly check if the PrivacyAPI is ready
			// this.checkInterval = setInterval(() => this.checkPrivacyApi(), 500); // Check every 500 milliseconds
			// Expose the handleApiCall method of the stub to the global object
			window.__privacyapi = privacyStub.handleApiCall.bind(privacyStub);
		},
	};
	// Initialize the stub
	window.__privacystub = privacyStub;
	window.__privacystub.init();
})(window);

// Example usage:
// window.__privacystub('QUERY_PERMISSIONS', response => console.log(response));
