import PropTypes from "@arc-fusion/prop-types";
import React, { Fragment } from "react";
import { getClasses } from "@washingtonpost/front-end-utils";
import {
  styled,
  theme,
  Carousel as WPDSCarousel
} from "@washingtonpost/wpds-ui-kit";
import getEnv from "~/components/utilities/env";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import {
  touchDeviceSel,
  carouselButtonCss as buttonCss
} from "../_utilities/style-helpers";
import { useLiveTicker } from "~/content/sources/live-ticker";
import {
  makeIsSameContent,
  transformFromAnsToFetched
} from "./LiveTicker.helpers";

/* Components */
import { CompoundLabel } from "./Label";

const env = getEnv();

// Fetch every 10 sec
const defaultPollFrequency = 10;

const getTimestamp = (item) => {
  let timestamp;
  try {
    timestamp = new Date(item.display_date).toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour: "numeric",
      minute: "2-digit"
    });
  } catch (ex) {
    timestamp = item.display_date;
  }
  return timestamp
    ? timestamp.replace(/\b(A|P)M\b/, "$1.m.").toLowerCase()
    : timestamp;
};

const WPDSCarouselContent = styled(WPDSCarousel.Content, {
  // NOTE: For edge-to-edge look on touch devices
  [touchDeviceSel]: {
    margin: `0 calc(-1 * ${theme.sizes["100"]})`
  }
});

const itemBorder = `1px solid ${theme.colors.gray400}`;
const itemBorderRadius = theme.radii["050"];
const WPDSCarouselItem = styled("div", {
  minWidth: "248px",
  lineHeight: theme.lineHeights["125"],
  borderTop: itemBorder,
  borderBottom: itemBorder,
  borderLeft: itemBorder,
  padding: `${theme.space["100"]} ${theme.space["125"]}`,
  "&:first-child": {
    borderRadius: `${itemBorderRadius} 0 0 ${itemBorderRadius}`,
    // NOTE: For edge-to-edge look on touch devices
    [touchDeviceSel]: {
      marginLeft: theme.space["100"]
    }
  },
  "&:last-child": {
    borderRight: `1px solid ${theme.colors.gray400}`,
    borderRadius: `0 ${itemBorderRadius} ${itemBorderRadius} 0`
  }
});

const StyledTimestamp = styled("div", {
  position: "relative",
  fontSize: theme.sizes["075"],
  lineHeight: theme.lineHeights["100"],
  color: theme.colors.red100,
  marginLeft: theme.sizes["075"],
  marginBottom: theme.sizes["025"],
  "&:before": {
    position: "absolute",
    left: "-0.75rem",
    top: "-0.25rem",
    content: "•",
    fontSize: theme.sizes["125"]
  }
});

const LiveTickerCarousel = ({
  itemsToUse,
  showTimestamps,
  itemHeadlineClass
}) => (
  <WPDSCarousel.Root css={{ position: "relative", clear: "both" }}>
    <WPDSCarousel.PreviousButton
      css={{ ...buttonCss, insetInlineStart: "0%" }}
      variant="secondary"
    />
    <WPDSCarousel.NextButton
      css={{ ...buttonCss, insetInlineStart: "100%" }}
      variant="secondary"
    />
    <WPDSCarouselContent
      onKeyDown={function noRefCheck() {}}
      onKeyUp={function noRefCheck() {}}
    >
      {itemsToUse.map((item, index) => {
        const timestamp = getTimestamp(item);
        return (
          <WPDSCarouselItem key={`${item.canonical_url}-carousel-${index}`}>
            <a
              href={item.canonical_url}
              key={`${item.display_date}_${index}`}
              data-link-detail={index + 1}
              className={itemHeadlineClass}
            >
              {showTimestamps && <StyledTimestamp>{timestamp}</StyledTimestamp>}
              <div className="overflow-hidden lc lc-3">
                {item.headlines.basic}
              </div>
            </a>
          </WPDSCarouselItem>
        );
      })}
    </WPDSCarouselContent>
  </WPDSCarousel.Root>
);

LiveTickerCarousel.propTypes = {
  itemsToUse: PropTypes.array,
  showTimestamps: PropTypes.bool,
  itemHeadlineClass: PropTypes.string
};

const LiveTicker = ({
  url,
  isLive,
  pollFrequency = defaultPollFrequency,
  subtypes,
  items = [],
  numToShow,
  numToShowCarousel,
  showTimestamps,
  compoundLabel
}) => {
  const { bp } = useBreakpoints();
  const isCarousel = bp === "xs";
  numToShow = isCarousel ? numToShowCarousel : numToShow;

  const refreshInterval =
    env === "prod"
      ? defaultPollFrequency * 1000
      : Math.max(defaultPollFrequency, pollFrequency) * 1000;
  const { items: fetchedItems } = useLiveTicker({
    url,
    subtypes,
    isLive,
    content: { content: { children: items.map(transformFromAnsToFetched) } },
    isSameContent: makeIsSameContent(numToShow),
    INTERVAL: refreshInterval
  });

  const itemsToUse = (fetchedItems.length === 0 ? items : fetchedItems).slice(
    0,
    numToShow
  );

  if (!itemsToUse || !itemsToUse.length) return null;

  const listClass =
    "blt blt-outside blt-tight blt-connected blt-dot-large blt-red left relative";
  const timestampClass = "gray-dark";
  const itemHeadlineClass = "font-xxxs gray-darker link";
  const paddingClass = "pb-xs";

  return (
    <Fragment>
      {compoundLabel && <CompoundLabel {...compoundLabel} />}
      <ul
        className={`list-none pa-0 ma-0 left ${paddingClass}`}
        data-link-group="live-ticker"
      >
        {isCarousel ? (
          <LiveTickerCarousel
            {...{
              itemsToUse,
              showTimestamps,
              itemHeadlineClass
            }}
          />
        ) : (
          itemsToUse.map((item, index) => {
            const timestamp = getTimestamp(item);
            return (
              <li
                // key={`liveTicker_${index}`}
                key={`${item.canonical_url}-${index}`}
                className={getClasses(listClass, {
                  [paddingClass]: index !== itemsToUse.length - 1
                })}
              >
                <a
                  href={item.canonical_url}
                  key={`${item.display_date}_${index}`}
                  data-link-detail={index + 1}
                  className={itemHeadlineClass}
                >
                  {showTimestamps && (
                    <div className={timestampClass}>{timestamp}</div>
                  )}
                  {item.headlines.basic}
                </a>
              </li>
            );
          })
        )}
      </ul>
    </Fragment>
  );
};

LiveTicker.propTypes = {
  url: PropTypes.string,
  isLive: PropTypes.bool,
  pollFrequency: PropTypes.number,
  subtypes: PropTypes.array,
  items: PropTypes.array,
  numToShow: PropTypes.number,
  numToShowCarousel: PropTypes.number,
  showTimestamps: PropTypes.bool,
  compoundLabel: PropTypes.object
};

export default LiveTicker;
