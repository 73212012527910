import React from "react";
import { RenderableProps } from "../../types";
import { FusionContext } from "../types";

export const baseContext: Partial<FusionContext> = {
  outputType: "default",
  contextPath: "/dr",
  deployment: "",
  metaValue: (which: string) =>
    which === "contentType" ? "homepage" : undefined
  // siteProperties
};

const ComponentContext = React.createContext<RenderableProps | undefined>(
  undefined
);

export const NextFusionContext = React.createContext<FusionContext | undefined>(
  undefined
);

export const ComponentProvider: React.FC<{
  children: React.ReactNode;
  value: RenderableProps;
}> = ({ children, value }) => {
  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  );
};
const useComponentContext = () => {
  return React.useContext(ComponentContext);
};

const useAppContext = () => {
  const context = React.useContext(NextFusionContext)!;
  return {
    ...baseContext,
    ...context,
    requestUri: context?.requestUri,
    isAdmin:
      typeof window !== "undefined" && window.location.href.includes("/admin") // hack - TODO fix later
  };
};

const useFusionContext = useAppContext;

export { useAppContext, useFusionContext, useComponentContext };
