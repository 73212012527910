import { DocumentNode, Renderable } from "../types";
import { objectToId } from "./objectToId";

type Mapper = (node: DocumentNode) => DocumentNode;

const mapTree = (mapFn: Mapper, node: DocumentNode): Renderable => {
  const newNode = mapFn(node);
  if (!newNode.children) {
    return newNode as Renderable;
  }
  const renderable: Renderable = {
    ...newNode,
    children: newNode.children.map((c) => {
      return mapTree(mapFn, c as any);
    })
  };
  return renderable;
};

export const denormalize = (
  root: DocumentNode,
  getter: (string: string) => DocumentNode
): Renderable => {
  return mapTree(
    (node) => {
      const id = objectToId(node) || "foo";
      const ent = getter(id);
      return ent || { ...node };
    },
    { ...root }
  );
};
