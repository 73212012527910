import React from "react";
import get from "lodash.get";
import { useSpartanChain, useSpartanFeature } from "../content";

const EditableProperty: React.FC<{
  path: string;
  value: any;
  className: string;
  tag: string;
  style: string;
}> = ({ path, value, className, tag, style }) => {
  const feature = useSpartanFeature()!;
  const chain = useSpartanChain()!;
  const props = feature.props || chain.props;
  const localValue = (path && get(props?.customFields, path)) || value;

  return React.createElement(tag || "span", {
    className,
    dangerouslySetInnerHTML: { __html: localValue },
    style
  });
};

export default EditableProperty;
