/* eslint-disable global-require */
import React, { ReactNode } from "react";
import {
  useCache,
  ContentRoot as SpectrumContentRoot,
  useContent,
  useEditableContent,
  useContentWithEdits
} from "./fusion-modules/content";
import {
  NextFusionContext,
  useAppContext,
  useFusionContext,
  useComponentContext,
  ComponentProvider
} from "./fusion-modules/context";
import { ContentCache } from "./types";

const NextFusionProvider: React.FC<{
  children: React.ReactNode;
  additionalContext?: { [key: string]: any };
}> = (props) => {
  const { children, additionalContext = {} } = props;
  return (
    <NextFusionContext.Provider
      value={{
        metaValue: () => "homepage",
        requestUri: "/homepage",
        contextPath: "/dr",
        outputType: "default",
        deployment: "foo",
        apiUrl: "",
        ...additionalContext
      }}
    >
      {children}
    </NextFusionContext.Provider>
  );
};

export const getDataFromTree = (
  AppTreeElement: React.ReactNode,
  cache: ContentCache
): Promise<void> => {
  const renderToStaticMarkup =
    typeof window === "undefined"
      ? // eslint-disable-next-line global-require
        require("react-dom/server").renderToStaticMarkup
      : null;
  try {
    renderToStaticMarkup(AppTreeElement);
  } catch (e) {
    // do nothing expect errors here in a lot of cases
    if (cache.hasPromises()) {
      return cache.wait().then(() => {
        return getDataFromTree(AppTreeElement, cache);
      });
    }
    return Promise.resolve();
  }
  if (!cache.hasPromises()) {
    return Promise.resolve();
  }
  return cache.wait().then(() => getDataFromTree(AppTreeElement, cache));
};

const ContentRoot: React.FC<{
  cache: ContentCache;
  additionalContext: { [key: string]: any };
  children?: ReactNode;
}> = ({ cache, children, additionalContext }) => {
  return (
    <NextFusionProvider additionalContext={additionalContext}>
      <SpectrumContentRoot cache={cache}>{children}</SpectrumContentRoot>
    </NextFusionProvider>
  );
};

export * from "./fusion-client";
export * from "./fusion-modules/consumer";
export * from "./types";
export * from "./contexts";

export {
  ComponentProvider,
  useComponentContext,
  useCache,
  ContentRoot,
  useAppContext,
  useFusionContext,
  useContentWithEdits,
  useContent,
  useEditableContent
};
