import PropTypes from "fusion-prop-types";

export const bigBoxAdTypes = {
  bigbox: "BigBox",
  bigbox_2: "BigBox 2",
  bigbox_3: "BigBox 3",
  bigbox_4: "BigBox 4",
  bigbox_5: "BigBox 5",
  bigbox_6: "BigBox 6",
  bigbox_7: "BigBox 7",
  bigbox_8: "BigBox 8"
};

// NOTE: These can be programmatically placed and need to be included
// among the allowedAdTypes proptype. However, they cannot be *curated* via the
// Zeus feature. Hence, they are kept in this separate list
// QUESTION: Could other ads go here, like tiffany tiles? others?
export const uncuratableAdTypesAdTypes = {
  bigbox_9: "BigBox 9",
  bigbox_10: "BigBox 10",
  bigbox_11: "BigBox 11",
  bigbox_12: "BigBox 12",
  bigbox_13: "BigBox 13",
  bigbox_14: "BigBox 14"
};

export const brandConnectAdTypes = {
  bs_card_front: "BrandConnect",
  bs_card_front_2: "BrandConnect 2",
  bs_card_front_3: "BrandConnect 3",
  bs_card_front_4: "BrandConnect 4"
};

const ads = [
  "leaderboard_1",
  "leaderboard_2",
  "leaderboard_3",
  "leaderboard_4",
  "interstitial",
  "desktop_interstitial",
  "banner_top",
  "tiffany_tile",
  "tiffany_tile_2",
  "bc_pb_homepage",
  ...Object.keys(bigBoxAdTypes),
  "fixedBottom",
  "howtovote",
  "marketwatch"
];

export const sharedProps = {
  isAdmin: PropTypes.bool,
  globalContent: PropTypes.shape({
    tracking: PropTypes.shape({
      show_ads: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    })
  })
};

export const adPropTypes = {
  adType: PropTypes.string,
  elementRef: PropTypes.object,
  minHeight: PropTypes.string,
  wrapperClasses: PropTypes.string,
  wrapperRef: PropTypes.object
};

export const ZeusPropTypes = {
  customFields: PropTypes.shape({
    adType: PropTypes.oneOf(ads).tag({
      labels: {
        leaderboard_1: "Leaderboard_1",
        leaderboard_2: "Leaderboard_2",
        leaderboard_3: "Leaderboard_3",
        leaderboard_4: "Leaderboard_4",
        bc_pb_homepage: "BC PB Homepage",
        howtovote: "howtovote",
        interstitial: "Interstitial",
        desktop_interstitial: "Desktop Interstitial",
        banner_top: "Banner Top",
        tiffany_tile: "Tiffany Tile",
        tiffany_tile_2: "Tiffany Tile 2",
        ...bigBoxAdTypes,
        fixedBottom: "fixedBottom",
        marketwatch: "Marketwatch"
      },
      defaultValue: "flex_ss_bb_hp"
    }),
    disableInterstitial: PropTypes.bool.tag({
      defaultValue: false,
      description:
        "For Interstitial Ad ONLY, if set to true then the Interstitial Ad will not render"
    })
  }),
  ...sharedProps
};

// NOTE: This is a combination of curatable ads, and uncuratable ads
const allowedAdTypes = [...ads, ...Object.keys(uncuratableAdTypesAdTypes)];

export const ZeusAdPropTypes = {
  adType: PropTypes.oneOf(allowedAdTypes),
  disableInterstitial: PropTypes.bool,
  ...sharedProps
};
