import pLimit from "p-limit";
import logger from "../logger";

/*
 * @param {batchSize} number - The size of the batch
 * @param {Object[]} batch - The batch (i.e array) of objects
 * @param {Function} mapFn - async function that will be invoked with each item in the batch
 *
 * @return {Promise<[]>} - An array of promises, one for each item in batch
 */
async function batchPromises<InputType, ReturnType>(
  batchSize: number,
  batch: Array<InputType>,
  mapFn: (a: InputType) => ReturnType
): Promise<Awaited<ReturnType>[] | undefined> {
  const limit = pLimit(batchSize);
  const promises = batch.map((entry) => limit(mapFn, entry));
  let results;
  try {
    results = await Promise.all(promises);
  } catch (e) {
    logger.error("", { error: e });
  }
  return Promise.resolve(results);
}

export { batchPromises };
