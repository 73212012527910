import get from "lodash.get";
import { PublishedDocumentModel, SlugItem } from "../types";

/*
 * Given a PublishedDocumentModel, parses the doc.snapshot.root.props.experiments into a list of SlugItems
 *
 * The experiments object has a strange form:
 * {
 *   [uuid]: { // uuid id of the first variant
 *     variant1Key: string // the control, called 'A' in the admin.
 *     variant2Key: string // first variant, called 'B' in the admin.
 *     variant3Key: string // second variant, called 'C' in the admin.
 *     variant3: uuid // uuid id of the second variant
 *   }
 * }
 *
 * If there is not a variant key, then that variant is not active
 *
 * @param {PublishedDocumentModel} doc - The object with 'experiment' key
 * @param {string} slug - The slug of the document needed to construct the SlugItem
 *
 * @return {SlugItem[]} - Includes variant (user-specified) and pubVariant ('B' or 'C')
 */
export const getExperiments = (
  doc: PublishedDocumentModel,
  slug: string
): SlugItem[] => {
  const experiments = get(doc, "snapshot.root.props.experiments", {});
  const rawExperimentId2 = Object.keys(experiments || {})?.[0];
  if (rawExperimentId2) {
    let timestamp = get(doc, "snapshot.timestamp");
    timestamp = timestamp ? Number(timestamp).toString() : timestamp;
    const info = experiments[rawExperimentId2];
    const list = [];
    if (info.variant2Key) {
      list.push({
        slug,
        variant: info.variant2Key,
        pubVariant: "B",
        timestamp
      });
    }
    if (info.variant3Key && info.variant3) {
      list.push({
        slug,
        variant: info.variant3Key,
        pubVariant: "C",
        timestamp
      });
    }
    if (list.length) return list;
  }
  return [];
};
