import React, { ReactNode, useRef } from "react";
import { useComponentContext } from "./context";
import { createContentCache } from "../fusion-client";
import { ContentCache } from "../types";
import { useAppContext } from "./context";

import get from "lodash.get";
import { ContentConfig } from "../types";
import logger from "../../logger";

export const useEditableContent = () => {
  const { localEdits: { items = {}, ...rest } = { items: {} } } =
    useComponentContext()!;
  return {
    editableContent: (defaultContent: { [key: string]: any }, path: string) => {
      const [key] = Object.keys(rest);
      const edits =
        (key && key in rest) ||
        (Object.keys(items).length ? items : defaultContent || {});
      const returnEdits = get(edits, path, {});
      return {
        text: returnEdits
      };
    },
    editableField: () => {},
    editableFields: () => {}
  };
};

export const ContentCacheContext = React.createContext(
  createContentCache({
    url: ""
  })
);

export const ContentRoot: React.FC<{
  cache: ContentCache;
  children?: ReactNode;
}> = ({ cache, children }) => {
  return (
    <ContentCacheContext.Provider value={cache}>
      {children}
    </ContentCacheContext.Provider>
  );
};

export const useCache = () => React.useContext(ContentCacheContext);

/** hannahmahon says (paraphrase):
 * when the page is being rendered on the server, it iterates through the render
 * tree and whenever it hits a useContent call, it caches the data in a local
 * cache (see createContentCache) so that when the page is finished rendering
 * server side and renders client side, the data is immediately available
 * In order:
 * 1. We hit a slug like /homepage and it does an internal fetch for the JSON
 * 2. Use or create a cache
 * 3. Call getDataFromTree to populate cache w/ our components in AppTree */
export const useContent = (
  { contentConfigValues, ...config }: ContentConfig,
  pollInterval: number
) => {
  config = {
    query: {
      ...(contentConfigValues || {})
    },
    ...config
  };
  const cache = useCache();
  const mountedRef = useRef<boolean>(false);

  const cachedContent = cache.getContent(config);
  if (typeof window === "undefined") {
    if (cachedContent === undefined) {
      cache.fetchContent(
        {
          ...config
        },
        true
      );
    }
  }

  const configIsValid = (config: ContentConfig) => {
    return !!config.source || !!config.contentService;
  };

  const [content, setContent] = React.useState(
    cachedContent || { isLoading: true }
  );

  const { isAdmin } = useAppContext();
  React.useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateContent = React.useCallback(
    (updateFromCache: Boolean) => {
      return cache
        .fetchContent(config, updateFromCache)
        .then((res) => {
          if (mountedRef.current) {
            setContent(res);
          }
        })
        .catch(() => {
          if (mountedRef.current) {
            setContent(null);
          }
          logger.error("failed to fetch", { config });
        });
    },
    [cache, config]
  );

  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (configIsValid(config)) {
      const isAdminFeed = isAdmin && pollInterval > 0;

      updateContent(true);

      if (isAdminFeed) {
        interval = setInterval(() => {
          updateContent(false);
        }, pollInterval);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [config, isAdmin, pollInterval, updateContent]);

  return content;
};

// TODO: Refactor to use useContent
export const useContentWithEdits = (
  props: ContentConfig,
  pollInterval: number
) => {
  const { contentConfigValues, ...config } = props || {};
  const updatedConfig = {
    query: {
      ...(contentConfigValues || {})
    },
    ...config
  };
  const content = useContent(updatedConfig, pollInterval);
  const componentContext = useComponentContext()!;

  // return as early as we can
  if (!componentContext || !componentContext?.localEdits) {
    return content;
  }

  const { localEdits } = componentContext;

  let edits: { [key: string]: any } = {};

  if (localEdits) {
    const { items, ...keys } = localEdits;
    if (updatedConfig && updatedConfig.source === "empty") {
      const key = Object.keys(keys).length && Object.keys(keys)[0];
      edits = key ? localEdits[key] : {};
    } else {
      if (content && content._id) {
        const key = Object.keys(keys).find((k) => {
          return (
            k === "random-key-should-not-matter-but-not-sure" ||
            k === content._id
          );
        });
        edits = key ? localEdits[key] : {};
      }
    }
  }
  if (!content) {
    return edits;
  }

  /**
   * I have a sneaking suspicion that `localEdits` and `edits` are NEVER POPULATED
   * meaning that we are reducing / inflating objects for nothing.
   *
   * Since I'm wary of the premise, lets put in a guardrail to only run reduce if
   * we absolutely have to.
   */
  const contentWithEdits =
    Object.keys(edits).length > 0
      ? Object.keys(edits).reduce((acc, k) => {
          const needsDeepMerge =
            typeof edits[k] === "object" &&
            content[k] &&
            typeof content[k] === "object";
          if (needsDeepMerge) {
            return {
              ...acc,
              [k]: {
                ...acc[k],
                ...edits[k]
              }
            };
          }
          return {
            ...acc,
            [k]: edits[k]
          };
        }, content)
      : content;

  return contentWithEdits;
};
