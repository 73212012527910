import { useExperimentsProvider } from "~/src/experiments";

/*
 * Gets the variant associated with the type of test. At most, there should only
 * ever be one type of tests (akamai).
 *
 * @type {string} type - whether to fetch the akamai variant (or something else we haven't added)
 *
 * @returns {number} - the varaint. usually the test is considered active when 1 is retuned
 */
const useVariant = (type = "akamai") => {
  const { akamaiTestId, getVariantGroup, getAllVariantGroups } =
    useExperimentsProvider();

  return Object.keys(getAllVariantGroups() || {})
    .filter((key) =>
      /akamai/.test(type) ? key === akamaiTestId : key !== akamaiTestId
    )
    .reduce((acc, key) => getVariantGroup(key), undefined);
};

/*
 * Gets the variant associated with the akamai test.
 *
 * @returns {string} - the varaint. usually the test is considered active when 1 is retuned
 */
export const useAkamaiVariant = () => useVariant("akamai");
