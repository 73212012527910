import { PublishedDocumentModel } from "../types";

export const getRenderables = (
  doc: PublishedDocumentModel
): { [key: string]: any } =>
  Object.keys(doc.snapshot || {})
    .filter((key) => /^renderable-/.test(key))
    .reduce((acc, key) => {
      acc[key] = doc.snapshot[key];
      return acc;
    }, {} as { [key: string]: any });
