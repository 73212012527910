import PropTypes from "fusion-prop-types";

export const topperDefaults = {
  // BASICS
  alignment: "left", // NOTE: No field in site service (yet)
  uppercase: false, // NOTE: No field in site service (yet)
  section: "/sports"
};

export const TopperPropTypes = {
  customFields: PropTypes.shape({
    section: PropTypes.string.tag({
      name: "Section (e.g. /sports)",
      defaultValue: topperDefaults.section
    }),
    alignment: PropTypes.oneOf(["center", "left"]).tag({
      name: "Alignment",
      description: `Alignment of topper: "left" and "center" supported. Consider restoring the default: ${topperDefaults.alignment}`,
      defaultValue: topperDefaults.alignment
    }),
    uppercase: PropTypes.bool.tag({
      name: "Uppercase section title",
      description: "When checked, the section title RENDERS UPPERCASE.",
      defaultValue: topperDefaults.uppercase
    })
  })
};
