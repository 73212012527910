import { getPrimarySectionId } from "@washingtonpost/site-ads";
import get from "lodash.get";
import set from "lodash.set";

import { formatToYYYYMMDD } from "~/components/utilities/date-utility";
import {
  BACKUP_PROMO_IMAGE,
  formatDuration,
  getAspectRatio,
  isGif,
  isMp4
} from "~/shared-components/video/video-helpers";
import {
  getArtWidth,
  getArtPosition
} from "~/components/features/fronts/flex-feature/utilities/index.js";
import { getCaption } from "./Caption.helpers.js";
import { isImageUrl } from "./Image.helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";

export const getPromoImage = ({ overrides, videoData } = {}) => {
  const coverArtUrl = get(overrides, "coverArtUrl");
  const hasCoverArt = coverArtUrl && !isGif(coverArtUrl);
  const url = hasCoverArt
    ? coverArtUrl
    : get(videoData, "fusion_additions.promo_image.url") ||
      get(videoData, "promo_items.basic.url");
  return isImageUrl(url) ? url : BACKUP_PROMO_IMAGE;
};

/**
 * @param {string} url - The image url.
 * @param {object} videoData - The video content
 * @returns {boolean} - Whether the object has a burned headline
 *
 * The headline-burned tag only applies to the promo_image. If the url is not that
 * image, we cannot know if the headline is burned in.
 */
export const getIsHeadlineBurned = ({ url, videoData } = {}) =>
  url &&
  url === get(videoData, "promo_items.basic.url") &&
  !!videoData?.taxonomy?.tags?.some(({ text }) =>
    /headline-burned/i.test(text)
  );

/**
 * @param {string} url - The image url.
 * @param {object} videoData - The video content
 * @returns {string} - The alt text
 *
 * For now, only do this when the headline is burned in.
 */
export const getAltTextForHeadlineBurned = ({ url, videoData } = {}) => {
  if (getIsHeadlineBurned({ url, videoData }))
    return get(videoData, "headlines.basic", undefined);
  return undefined;
};

export const getInlineVideoOverlay = (
  videoData,
  isLive,
  artOverlayShow,
  artOverlayText,
  includeDurationOrLive
) => {
  const getText = () => {
    if (isLive) return undefined;
    return artOverlayText || "Play Video";
  };

  const getSecondaryText = () => {
    if (isLive) return artOverlayText || "Live";
    return includeDurationOrLive
      ? formatDuration(get(videoData, "duration") / 1000)
      : undefined;
  };

  return {
    text: artOverlayShow ? getText() : undefined,
    secondary_text: getSecondaryText(),
    secondary_style: isLive ? "secondary_live" : "secondary",
    prefix_icon: "play"
  };
};

/**
 * Check for url ending with _live.html, _video.html or _video.html-\d+
 * Why is -\d+ a valid URL? URL service increments it on our behalf sometimes.
 * Canonical URL format will change eventually to not have .html,
 * but for now it's a valid URL
 *
 * @param {string} url - The url to test
 * @return {boolean} - returns true if this url is for a video
 */
export const isVideoUrl = (url) => {
  return /_(video|live).html(-\d+)?$/.test(
    (url || "").split("?")[0].split("#")[0]
  );
};

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is a video
 */
export const isVideo = (content) =>
  !!/video/i.test(content?.type) && content?.streams;

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is a vertical video
 */
export const isVerticalVideo = (content) =>
  isVideo(content) &&
  !!content?.taxonomy?.tags?.some(({ text }) => /vertical-video/i.test(text));

/**
 * Needed when backing content is a video and it should play inline
 * In future, when there is a proper getVideo() that serves
 * both desktop and jsonapp, this could probably become
 * part of that.
 */
export const getVideoData = ({ content, videoData, overrides = {} }) => {
  const { artHide, alternateArt, inlineVideo } = overrides;
  if (!artHide && !alternateArt && inlineVideo) {
    // NOTE: Case 1 -- If promo_items.video exists, use it.
    // For now, only websked collections exploit this
    const promoVideo = get(content, "promo_items.video");
    if (promoVideo) {
      // NOTE: override promo_item.basic of the video with promo_items.basic
      // from the underlying item
      const promoImage = get(content, "promo_items.basic");
      if (promoImage?.url) set(promoVideo, "promo_items.basic", promoImage);
      return promoVideo;
    }
    // NOTE: Case 2 -- If the underlying item is a video, use it.
    const url = get(
      content,
      "fusion_additions.links_to_use.dangerous.canonical"
    );
    if (isVideoUrl(url)) return content;
  }
  return videoData;
};

export const getVideo = ({
  content,
  videoData,
  artSlot,
  overrides = {},
  metaValue = () => {}
}) => {
  const {
    artPosition,
    artSize,
    artOverlayShow,
    artOverlayText,
    includeDurationOrLive,
    allowAnimation
  } = overrides;
  let { autoplay } = overrides;

  const section = get(videoData, "additional_properties.subsection");
  const uuid = get(videoData, "_id");
  const displayDate = get(videoData, "display_date");
  const isLive = get(videoData, "video_type") === "live";
  const tags = get(videoData, "taxonomy.tags", []).map((tag) => tag.text);
  const useYouTube = tags.includes("force-youtube-hp");
  const forceHd = tags.includes("force-hd");
  const loopingTag = tags.find((_) => /^looping-/.test(_));
  const isLooping = !!loopingTag;
  const hideControls = /^looping-promo/.test(loopingTag);
  autoplay = isLooping ? true : !!autoplay;

  const getVideoName = () => {
    return `${section || "wp-video"}:video - ${uuid
      .split("-")
      .pop()} - ${formatToYYYYMMDD(displayDate)} - ${get(
      videoData,
      "headlines.basic"
    )}`;
  };

  const url = getPromoImage({ videoData, overrides });
  const isHeadlineBurned = getIsHeadlineBurned({ url, videoData });
  const altText = getAltTextForHeadlineBurned({ url, videoData });
  const caption = getCaption({ content, videoData, artSlot, overrides });
  const captionText = get(caption, "text", undefined);
  const overlay = getInlineVideoOverlay(
    videoData,
    isLive,
    artOverlayShow,
    artOverlayText,
    includeDurationOrLive
  );
  const videoAdObj = get(videoData, "additional_properties.advertising", {});
  const mediaType = useYouTube ? "youtube" : "wp-video";

  // START: duration
  let duration = get(videoData, "duration");
  duration =
    duration && !Number.isNaN(duration)
      ? (duration = Math.round(duration / 1000))
      : undefined;

  // START: promoVideo
  const promoVideo = {
    is_looping: true,
    url: get(videoData, "promo_items.gif.url")
  };
  const usePromoVideo = allowAnimation && isMp4(get(promoVideo, "url"));
  // END: promoVideo

  const allowPreRoll = !(!overrides.ads || isLooping);

  return {
    media: {
      art_position: getArtPosition(artPosition),
      art_width: getArtWidth(artSize),
      aspect_ratio: getAspectRatio(videoData),
      caption: captionText,
      mime: isHTMLFragment(captionText) ? "text/html" : undefined,
      mediaType,
      overlay,
      url,
      altText,
      isHeadlineBurned,
      video: {
        adconfig: allowPreRoll
          ? {
              commercialAdNode: get(videoAdObj, "commercialAdNode", ""),
              primarySectionId: getPrimarySectionId(videoData, "contentApi"),
              playVideoAds:
                overrides.ads && get(videoAdObj, "playVideoAds", ""),
              allowPrerollOnDomain: get(videoAdObj, "allowPrerollOnDomain", ""),
              enableAutoPreview: get(videoAdObj, "enableAutoPreview", ""),
              playAds: get(videoAdObj, "playAds", ""),
              autoPlayPreroll: get(videoAdObj, "autoPlayPreroll", ""),
              enableAdInsertion: get(videoAdObj, "enableAdInsertion", ""),
              videoAdZone: get(videoAdObj, "videoAdZone", ""),
              adSetUrl: get(videoAdObj, "adSetUrls.apps", "")
            }
          : undefined,
        tracking: {
          content_id: uuid,
          page_name: metaValue("pageName"),
          video_category: get(
            videoData,
            "additional_properties.videoCategory",
            "None"
          ),
          video_section: section,
          video_name: getVideoName(),
          video_source: get(videoData, "source.name")
        },
        duration,
        ...(isLive ? { isLive } : {}),
        ...(isLooping ? { isLooping } : {}),
        ...(hideControls ? { hideControls } : {}),
        ...(forceHd ? { forceHd } : {}),
        ...(autoplay ? { autoplay } : {}),
        promo: usePromoVideo ? promoVideo : undefined,
        streams: get(videoData, "streams"),
        youtube_id: get(videoData, "additional_properties.youtubeEventId")
      }
    }
  };
};
