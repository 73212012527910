import React from "react";
import { Box, styled } from "@washingtonpost/wpds-ui-kit";
import { AskThePostAiLandingPage } from "@washingtonpost/ask-the-post-ai";

const StyledComponentContainer = styled(Box, {
  width: "100%"
});

export const AskThePostAiContent = () => {
  return (
    <StyledComponentContainer>
      <AskThePostAiLandingPage app="hp_mv-atp-ai" />
    </StyledComponentContainer>
  );
};
