/* eslint-disable no-console */
import chalk from "chalk";

const logFuncs: { [key: string]: Function } = {
  debug: console.debug,
  info: console.log,
  error: console.error
};

const colorFuncs: { [key: string]: chalk.Chalk | Function } = {
  debug: (a) => a,
  info: chalk.greenBright,
  error: chalk.redBright
};

const log = ({
  level,
  message,
  obj
}: {
  level: string;
  message: string;
  obj?: object;
}): object => {
  const time = new Date().toISOString();
  const record = { ...obj, time, level, message };

  if (typeof window !== "undefined") logFuncs[level](time, level, message, obj);
  else if (process.env.NILE_ENV === "local")
    logFuncs[level](colorFuncs[level](JSON.stringify(record)));
  else logFuncs[level](JSON.stringify(record));

  return record;
};

const debug = (message: string, obj?: object): object =>
  log({ message, obj, level: "debug" });

const info = (message: string, obj?: object): object =>
  log({ message, obj, level: "info" });

const error = (message: string, obj?: object): object =>
  log({ message, obj, level: "error" });

export default { debug, info, error };
