export const webSiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "The Washington Post",
  alternateName: ["The Post", "WaPo", "WP", "washington post"],
  url: "https://www.washingtonpost.com/",
  publisher: { "@id": "washingtonpost.com", name: "The Washington Post" },
  image: [
    {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/RUGLRTKULZB6XPESJMXXJVLTDI.jpeg&w=1200",
      height: 630,
      width: 1200
    },
    {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/RUGLRTKULZB6XPESJMXXJVLTDI.jpeg&w=1200&h=900",
      height: 900,
      width: 1200
    },
    {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/RUGLRTKULZB6XPESJMXXJVLTDI.jpeg&w=1200&h=1200",
      height: 1200,
      width: 1200
    }
  ]
};
