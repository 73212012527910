import React from "react";
import PropTypes from "prop-types";
import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { useChainContext } from "~/shared-components/ChainContext";
import { getStylesFromSize } from "../_utilities/style-helpers";
import getRelatedLinksToolbarItems from "./RelatedLinks.toolbar";

/* Components */
import { CompoundLabel } from "./Label";

const LiStyled = styled("li");

const RelatedLink = ({ item, classNames, menuItems, css, delim }) => {
  const { EditableProperty } = useAssemblerContext();
  const { text, url, textKey } = item;

  const relatedLink = (
    <EditableProperty
      value={text}
      tag="span"
      path={textKey}
      menuItems={menuItems}
    />
  );

  return (
    <LiStyled
      className={classNames}
      css={css}
      style={delim ? { "--delim": `'${delim}'` } : {}}
    >
      {url ? <a href={url}>{relatedLink}</a> : relatedLink}
    </LiStyled>
  );
};

RelatedLink.propTypes = {
  classNames: PropTypes.string,
  item: PropTypes.object,
  menuItems: PropTypes.array,
  css: PropTypes.object,
  delim: PropTypes.string
};

const RelatedLinks = (props) => {
  const { items, info, compoundLabel } = props;
  const { useDesktopOrdering } = useChainContext();

  if (!items || !items.length) return null;

  const { arrangement, size, prependBullet, delim, fontWeight, fontFamily } =
    info;

  const wrapperClass = getClasses("pb-xs", {
    "pt-xs": !/related-links/.test(size)
  });

  let ulClass = "list-none ma-0 pt-0 pr-0 pl-0 gray-darkest";
  let liClass = "bb relative";
  let liNotFirstClass = "";
  let liNotLastClass = "";
  const bulletClass = "blt blt-diamond blt-gray";

  if (arrangement === "side-by-side") {
    liClass = `${liClass} dib`;
    ulClass = `${ulClass}`;
    liNotFirstClass = `${bulletClass} blt-inbetween`;
  } else {
    liClass = getClasses(liClass, {
      [`${bulletClass} blt-outside`]: prependBullet
    });
    liNotLastClass = /related-links/.test(size) ? "pb-xxs" : "pb-sm";
  }

  const css = {
    ...getStylesFromSize(size),
    fontFamily: theme.fonts[fontFamily],
    fontWeight: theme.fontWeights[fontWeight]
  };

  return (
    <div className={wrapperClass}>
      {compoundLabel && <CompoundLabel {...compoundLabel} />}
      <ul className={ulClass}>
        {items.map((item, index) => (
          <RelatedLink
            key={index}
            classNames={getClasses(liClass, {
              [liNotFirstClass]: index !== 0,
              [liNotLastClass]: index !== items.length - 1
            })}
            item={item}
            menuItems={getRelatedLinksToolbarItems({
              ...props,
              arrangement,
              item,
              useDesktopOrdering
            })}
            css={css}
            delim={delim}
          />
        ))}
      </ul>
    </div>
  );
};

const relatedLinkShape = PropTypes.shape({
  text: PropTypes.string,
  url: PropTypes.string
});

RelatedLinks.propTypes = {
  items: PropTypes.arrayOf(relatedLinkShape),
  info: PropTypes.object,
  relatedLinksOrder: PropTypes.string,
  // TODO: Define a shape somewhere, and use that shape here.
  compoundLabel: PropTypes.object,
  menuItems: PropTypes.array,
  setEntity: PropTypes.func,
  value: PropTypes.string
};

export default RelatedLinks;
