import { Renderable, Doc } from "../types";
import { objectToId } from "./objectToId";

export function normalize(root: Renderable): Doc {
  const entities = {} as Doc;
  const walk = (
    node: Renderable | Renderable[],
    callback: (item: any) => void
  ): void => {
    if (Array.isArray(node)) {
      node.map((n) => {
        walk(n, callback);
      });
      return;
    }
    if (node.children && Array.isArray(node.children)) {
      const children = node.children;
      const newNode = {
        ...node,
        children: node.children.map((n) => {
          return {
            id: objectToId(n)
          };
        })
      };
      callback(newNode);
      walk(children, callback);
      return;
    } else {
      callback(node);
    }
  };

  root.id = "root";
  walk(root, (n) => (entities[objectToId(n)] = n));
  return entities;
}
