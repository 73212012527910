import { Doc, FeatureOrChain, Layout, Section } from "../types";

// NOTE: the admin and render endpoints uses this
function addRootCustomFieldsFromSnapshot(currentDoc: Doc): void {
  const rootCustomFields = currentDoc?.root?.props?.customFields;
  currentDoc?.root?.children?.forEach(({ id: zoneId }) => {
    currentDoc?.[zoneId]?.children?.forEach(({ id: chainorfeatureId }) => {
      const chainorfeature = currentDoc?.[chainorfeatureId];
      if (chainorfeature?.props) {
        chainorfeature.props.rootCustomFields = rootCustomFields;
      }
      currentDoc?.[chainorfeatureId]?.children?.forEach(({ id: featureId }) => {
        const feature = currentDoc?.[featureId];
        if (feature?.props) {
          feature.props.rootCustomFields = rootCustomFields;
        }
      });
    });
  });
}

// NOTE: the jsonapp endpoint uses this
const addRootCustomFieldsFromRendering = (currentDoc: Layout): void => {
  // @ts-ignore
  const rootCustomFields = currentDoc?.props?.customFields;
  currentDoc?.children?.forEach((zone: Section) => {
    zone?.children?.forEach((chainorfeature: FeatureOrChain) => {
      if (chainorfeature?.props) {
        chainorfeature.props.rootCustomFields = rootCustomFields;
      }
      chainorfeature?.children?.forEach((feature) => {
        if (feature?.props) {
          feature.props.rootCustomFields = rootCustomFields;
        }
      });
    });
  });
};

export const addRootCustomFields = (currentDoc: Doc | Layout): void => {
  if (currentDoc) {
    if ("root" in currentDoc && currentDoc?.root) {
      addRootCustomFieldsFromSnapshot(currentDoc);
    }
    if (currentDoc?.children) {
      // @ts-ignore
      addRootCustomFieldsFromRendering(currentDoc);
    }
  }
};
