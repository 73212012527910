import React from "react";
import getEnv from "~/components/utilities/env";

function loadPrivacyStub() {
  const getDomainBase = {
    dev: "https://subscribe.digitalink.com",
    sandbox: "https://subs-stage.washingtonpost.com",
    prod: "https://www.washingtonpost.com/subscribe"
  };
  const privacyStubUrl = getDomainBase[getEnv()];
  const privacyStubSrc = `${privacyStubUrl}/privacy-fe-api/privacy-api-stub.min.js`;
  return (
    <script src={privacyStubSrc} id="privacyStub" key="privacyStub"></script>
  );
}

export default loadPrivacyStub;
