import { Doc } from "../types";
export interface ResponseCache {
  [key: string]: any;
}

export type FetchConfig = {
  endpoint: string;
  queryParams: object;
  fetchOptions: Partial<RequestInit>;
  transform: (data: any) => any;
};

export interface ContentCache {
  debug: () => any;
  extract: () => ResponseCache;
  wait: () => Promise<void>;
  hasPromises: () => boolean;
  serialize: () => string;
  getContent: (config: ContentConfig) => any;
  getContentFromResponseOrPromiseCache: (
    key: string,
    updateFromCache: Boolean
  ) => Promise<any> | undefined;
  fetchContent: (
    config: ContentConfig,
    updateFromCache: Boolean
  ) => Promise<any>;
  fetchExternalContent: (config: FetchConfig) => Promise<any>;
}

export interface SiteRootProps {
  tree: Doc;
  additionalContext?: { [key: string]: any };
  optimizeIds?: string[];
  documentRepo: any;
  onSelectDocument: (id: string) => void;
  children?: (opts: any) => any;
  components: { [key: string]: any };
  alerts: { [key: string]: any };
  contentCache: ContentCache;
  apiUrl: string;
  requestUri: string;
}

export interface ContentConfig {
  source?: string;
  query?: { [key: string]: string };
  transform?: any;
  filter?: string;
  contentService?: string;
  contentConfigValues?: { [key: string]: string };
  url?: string;
  headers?: any;
  enableServeStale?: boolean;
}

export interface FusionContext {
  outputType: string;
  contextPath: string;
  deployment: string;
  metaValue: (which: string) => string | undefined;
  requestUri: string;
  apiUrl: string;
}
