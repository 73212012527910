import React from "react";
import PropTypes from "prop-types";
import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { getStylesFromSize } from "../_utilities/style-helpers";

const defaultSize = "large";

export const CountStyled = styled("div");

const Count = ({ count = 0, size = defaultSize }) => {
  count = Math.round(Number.parseInt(count, 10) || 0);
  if (Number.isNaN(count) || count < 1) return null;

  const css = {
    ...getStylesFromSize(/medium/.test(size) ? "standard" : "large"),
    fontWeight: theme.fontWeights.bold
  };

  const className = getClasses("dib", {
    "b bb bc-offblack": size === "large"
  });

  return (
    <CountStyled className={className} css={css}>
      {count}
    </CountStyled>
  );
};

Count.propTypes = {
  count: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium"])
};

export default Count;
