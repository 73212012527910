import React from "react";
import PropTypes from "prop-types";
import { useAppContext } from "fusion-context";
import WallUI from "@washingtonpost/wall-ui";
import { useContent } from "fusion-content";
import { StickyPlayerProvider } from "@washingtonpost/media-components/core/audio/StickyPlayer";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { BreakpointProvider } from "~/shared-components/BreakpointContext";
import { MultiViewProvider } from "~/shared-components/MultiViewContext";
import { UserProvider } from "~/components/contexts/user-context";
import { VideoProvider } from "~/components/contexts/video-context";
import { NewsletterSignupProvider } from "~/components/contexts/newsletter-signup-context";
import { RenderedContentProvider } from "~/components/contexts/rendered-content-context";

import ErrorBoundary from "~/shared-components/ErrorBoundary";
import Analytics from "~/components/core/tracking/index.jsx";
import CookieMonster from "~/components/core/cookie-monster/cookie-monster";

import {
  initBreakpointMediaQueries,
  clickHandler,
  submitHandler
} from "~/components/layouts/homepage.helpers";
import { SandwichLayoutProvider } from "~/shared-components/layouts/SandwichLayoutContext";
import { layoutToZonesMap } from "~/components/layouts/helpers";

import { AdRenderProvider } from "~/components/utilities/ad-rendered-context";
import contentFilter from "../../content/filters/site-subs-offer";
import { HomepagePropTypes } from "../../proptypes/homepage";
import { scrollEventTracking } from "./homepage.helpers";

import { useMultiView } from "../../shared-components/MultiViewContext";
import MultiViewTabs from "../features/fronts/multi-view-nav/default";
import LiveUpdateHpAlert from "~/shared-components/features/fronts/alert/default";
import LiveUpdateVideoLiveBar from "~/shared-components/features/fronts/video-live-bar/default";

// eslint-disable-next-line react/prop-types
const LayoutWrapper = ({ children }) => {
  const { metaValue, isAdmin } = useAppContext();
  const contentType = metaValue("contentType");
  const section = metaValue("section");
  const subsection = metaValue("subsection");
  const commercialNode = metaValue("commercial_node");

  const handlerProps = [
    contentType,
    section === "undefined" ? undefined : section,
    commercialNode,
    subsection === "undefined" ? undefined : subsection
  ];

  const attrs = isAdmin
    ? {}
    : {
        onClick: (event) => {
          clickHandler(event, ...handlerProps);
        },
        onContextMenu: (event) => {
          clickHandler(event, ...handlerProps);
        },
        onSubmit: (event) => {
          submitHandler(event, ...handlerProps);
        }
      };

  return <div {...attrs}>{children}</div>;
};

const useResettingRender = () => {
  const [resettingState, setIsResetting] = React.useState(false);
  React.useEffect(() => {
    const matches = document.querySelectorAll(
      "div[data-fusion-message='Could not render component [features:fronts/flex-feature]']"
    );
    if (matches && matches.length > 0) {
      setIsResetting(true);
      setTimeout(() => {
        setIsResetting(false);
      });
    }
  }, []);
  return resettingState;
};

const FooterWrapper = styled("div", {
  position: "relative",
  backgroundColor: theme.colors.background
});

const Zones = ({
  topFurniture,
  fullWidthTopper,
  mainContent,
  fullWidthFooter,
  footerFurniture
}) => {
  const { isMultiView, isReady, setTabContentByIndex } = useMultiView();
  setTabContentByIndex(mainContent, 0);
  const { metaValue } = useAppContext();
  const isResetting = useResettingRender();
  const layoutClasses =
    "hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans";

  React.useEffect(() => {
    if (isReady) {
      scrollEventTracking();
    }
  }, [isReady]);

  if (isResetting) {
    return null;
  }
  const isHomepage = metaValue("contentType") === "homepage";

  return (
    <React.Fragment>
      <RenderedContentProvider>
        {topFurniture} {/* top-furniture */}
        {/* live events elements */}
        {isHomepage && !isMultiView && (
          <React.Fragment>
            <LiveUpdateHpAlert />
            <LiveUpdateVideoLiveBar />
          </React.Fragment>
        )}
        {fullWidthTopper} {/* full-width-topper */}
        <main id="main-content">
          <SandwichLayoutProvider
            value={{
              section: "main-content",
              // layoutVars: topTableGridVars,
              layoutClasses
            }}
          >
            <VideoProvider>
              <NewsletterSignupProvider>
                {isMultiView ? <MultiViewTabs /> : mainContent}
              </NewsletterSignupProvider>
            </VideoProvider>
          </SandwichLayoutProvider>
        </main>
        {fullWidthFooter} {/* full-width-footer */}
        <FooterWrapper>
          <footer className="hpgrid-max-width ma-auto overflow-hidden mb-xl -mb-xl-ns">
            {footerFurniture} {/* footer-furniture */}
            <CookieMonster />
          </footer>
        </FooterWrapper>
      </RenderedContentProvider>
    </React.Fragment>
  );
};

Zones.propTypes = {
  topFurniture: PropTypes.element,
  fullWidthTopper: PropTypes.element,
  mainContent: PropTypes.element,
  fullWidthFooter: PropTypes.element,
  footerFurniture: PropTypes.element
};

/**
 * Homepage layout that returns layout containers to place features in.
 * Homepage features are wrapped in a Navigation context, to allow features access logged in state.
 *
 * @param {object} props
 * @returns Layout component that can render features on the homepage of the Washington Post.
 */
const Homepage = (props) => {
  // Wall-UI variables
  const { globalContent } = useAppContext();
  const { isMobile } = useAssemblerContext();
  const siteSubsData = useContent({
    source: "site-homepage",
    filter: contentFilter
  }) || { site: {} };

  return (
    <BreakpointProvider
      queries={initBreakpointMediaQueries()}
      isMobile={isMobile}
    >
      <MultiViewProvider>
        <UserProvider>
          <AdRenderProvider>
            <LayoutWrapper>
              <StickyPlayerProvider>
                <Analytics />
                <Zones
                  topFurniture={props.children[0]}
                  fullWidthTopper={props.children[1]}
                  mainContent={props.children[2]}
                  fullWidthFooter={props.children[3]}
                  footerFurniture={props.children[4]}
                />
              </StickyPlayerProvider>
            </LayoutWrapper>
          </AdRenderProvider>
        </UserProvider>
        <ErrorBoundary>
          <WallUI globalContent={globalContent} siteSubsData={siteSubsData} />
        </ErrorBoundary>
      </MultiViewProvider>
    </BreakpointProvider>
  );
};

Homepage.sections = layoutToZonesMap.homepage;
Homepage.propTypes = HomepagePropTypes;
export default Homepage;
