import get from "lodash.get";
import { getPrimarySectionId } from "@washingtonpost/site-ads";
import {
  useContent,
  fetchAsyncContent
} from "~/spartan-homepage/fusion-modules/content";
import { removeWWW } from "~/components/utilities/www-remover";
import { external, headers } from "~/prism.config";
import { getFrontPageName } from "~/components/utilities/meta-helper";
import getResizedUrl from "~/components/core/elements/image/resize-url";

export const getPagePath = (siteServiceObj) =>
  (
    removeWWW(siteServiceObj?.site?.site_url) ||
    siteServiceObj?._id?.concat("/").replace(/(.*)homepage\/$/, RegExp.$1) ||
    ""
  ).replace(/\/\//g, "/");

/**
 *
 * @param {obj} siteServiceObj site service obj -- { _id: "", site: {}, etc. }
 * @param {bool} isHomepage homepage or front
 * @returns {obj} { canonicalUrl: "", commercial_node: "", etc. }
 */
export const frontsData = (siteServiceObj, isHomepage) => {
  return {
    primary_section_id: getPrimarySectionId(siteServiceObj, "siteService"),
    canonicalUrl: get(siteServiceObj, "site.path_fusion"),
    commercial_node:
      get(siteServiceObj, "_admin.commercial_node") ||
      get(siteServiceObj, "_admin.tracking_node"),
    contentType: isHomepage ? "homepage" : "front",
    description: get(siteServiceObj, "site.site_description"),
    keywords: get(siteServiceObj, "site.site_keywords"),
    pageName: getFrontPageName({
      pageName:
        get(siteServiceObj, "site.tracking_page_name") ||
        get(siteServiceObj, "site.site_url_section"),
      isFront: !isHomepage,
      isJsonApp: false
    }),
    pagePath: getPagePath(siteServiceObj),
    pageType: isHomepage ? undefined : "front",
    section: get(siteServiceObj, "site.tracking_section"),
    social_share_image: getResizedUrl(
      get(
        siteServiceObj,
        "site.social_share_image",
        "https://www.washingtonpost.com/pb/resources/img/twp-social-share.png"
      ),
      { w: 1484 }
    ),
    subsection: get(siteServiceObj, "site.tracking_subsection"),
    tagline: get(siteServiceObj, "site.site_tagline"),
    title:
      `${get(siteServiceObj, "site.site_title")} - The Washington Post` || ""
  };
};
/**
 *
 * @param {string} globalContentVal e.g. "politics"
 * @returns {string} "/politics"
 */
export function sectionForSlug(globalContentVal) {
  let section = "/";
  if (typeof globalContentVal !== "string") return section;
  if (globalContentVal) {
    if (globalContentVal[0] === "/") section = `/${globalContentVal.slice(1)}`;
    else section = `/${globalContentVal}`;
  }

  return section;
}
/**
 *
 * @param {string} globalContentVal admin's Global Content field value, e.g. "politics"
 * @returns {obj} page's metadata obj -- { canonicalUrl: "", commercial_node: "", etc. }
 */
export const fetchPageMetaData = async (globalContentVal) => {
  // If globalContentVal is ever undefined, make it "homepage"
  const val = globalContentVal || "homepage";
  const section = sectionForSlug(val);
  const isHomepage = val === "homepage";

  const siteContent = await fetchAsyncContent({
    source: "site-service-section",
    query: { section }
  });

  const metaData = frontsData(get(siteContent, "q_results[0]"), isHomepage);

  return metaData;
};

// NOTE: Not currently using this anywhere
export const usePageMetaData = (slug) => {
  const url = external
    ? `${external}/api`
    : "https://www.washingtonpost.com/prism/api";
  const section = sectionForSlug(slug);
  const isHomepage = slug === "homepage";

  const siteContent = useContent({
    source: "site-service-section",
    query: { section },
    url,
    headers
  });

  const metaData = frontsData(
    get(siteContent, "q_results[0]"),
    isHomepage,
    false,
    slug
  );

  return metaData;
};
