import React from "react";
import { useAdotOfferData } from "@washingtonpost/subs-hooks";
import { isSubscriber } from "@washingtonpost/subs-sdk";
import * as uiKit from "@washingtonpost/wpds-ui-kit";
import SubsMainBody from "./_children/subs-main-body";
import SubsLabel from "./_children/subs-label";
import SubsImage from "./_children/subs-image";

const contentDefault = {
  size: "medium",
  theme: "default",
  isNoBackground: "false",
  headline: "Stay ahead of what's ahead.",
  ctaText: "Subscribe",
  isTextCta: "false",
  logoStyle: "long"
};

// TODO: inline styles need to be switched over to classNames once those are available
const checkThemeColorStyle = (theme, isNoBackground) => {
  const defaultBgColor =
    isNoBackground === "true" ? uiKit.theme.colors.gray700 : "";
  switch (theme) {
    case "white":
      return {
        bgColor: uiKit.theme.colors.gray700,
        btnColor: "#166dfc",
        btnHoverColor: "#003399"
      };
    default:
      return {
        bgColor: defaultBgColor || uiKit.theme.colors.blue600,
        btnColor: "#166dfc",
        btnHoverColor: "#003399"
      };
  }
};

const InlineSubsModule = () => {
  const adotData = useAdotOfferData({ selectedPromoKey: "hp_sub_promo" });

  const { isCancelled, offerType } = adotData || {};
  // For cancelled subs, it should not be rendered if offerType is not "RETENTION" and text variables are not returned
  const isCancelledOffer = Boolean(
    isCancelled &&
      offerType === "RETENTION" &&
      adotData?.HP_INLINE_HEADLINE &&
      adotData?.HP_INLINE_CTA_TEXT
  );

  if (
    typeof window === "undefined" ||
    (isSubscriber() && !isCancelledOffer) ||
    !adotData ||
    adotData.hasSWGEntitlement
  ) {
    return null;
  }

  const {
    HP_INLINE_THEME: subsTheme = contentDefault.theme,
    HP_INLINE_IS_NO_BACKGROUND: isNoBackground = contentDefault.isNoBackground,
    HP_INLINE_SIZE: subsSize = contentDefault.size,
    HP_INLINE_HEADLINE: subsHeadline = contentDefault.headline,
    HP_INLINE_CTA_TEXT: subsCtaText = contentDefault.ctaText,
    HP_INLINE_IS_TEXT_CTA: subsIsTextCta = contentDefault.isTextCta,
    HP_INLINE_LOGO_STYLE: subsLogoStyle = contentDefault.logoStyle,
    HP_INLINE_CLICK_TRIGGER_TEXT: subsTriggerText,
    HP_INLINE_PRIMARY_LABEL: subsPrimaryLabel,
    HP_INLINE_SECONDARY_LABEL: subsSecondaryLabel,
    HP_INLINE_LEFT_IMG: subsLeftImg,
    HP_INLINE_RIGHT_IMG: subsRightImg,
    HP_INLINE_MOBILE_IMG: subsMobileImg,
    HP_INLINE_SUBSCRIPTION_PRICING: subsPricing = "s_v",
    HP_INLINE_SUBSCRIPTION_LOCATION:
      subsSubscriptionLocation = "ONSITE_HOMEPAGE_MODULE",
    HP_INLINE_CONTENT_TYPE: subsContentType = "homepage",
    HP_INLINE_CTA_URL: subsCtaUrl
  } = adotData || {};

  const { bgColor, btnColor, btnHoverColor } = checkThemeColorStyle(
    subsTheme,
    isNoBackground
  );

  return (
    <div className="hide-for-print mb-lg">
      {subsLogoStyle.toLowerCase().trim() !== "none" && (
        <SubsLabel
          subsPrimaryLabel={subsPrimaryLabel}
          subsSecondaryLabel={subsSecondaryLabel}
          isShortLogo={
            subsLogoStyle === "short" &&
            (subsTheme === "white" || isNoBackground === "true")
          }
        />
      )}

      <div
        className={`flex flex-column flex-ns-row relative items-center b bh bc-gray-lighter pl-sm pr-sm pl-0-ns pr-0-ns ${
          subsLeftImg || subsRightImg ? "justify-between" : "justify-center"
        }`}
      >
        {/* background color */}
        <div
          className="absolute w-100 h-100 left-0 top-0"
          style={{ backgroundColor: bgColor }}
          data-qa="inline-subs-bg"
        />

        {/* mobile image */}
        {subsMobileImg && (
          <SubsImage
            subsImgUrl={subsMobileImg}
            width={400}
            height={150}
            containerClass={"flex dn-ns w-100 justify-center mb-sm z-1"}
          />
        )}

        {/* desktop left side image */}
        {(subsLeftImg || subsRightImg) && (
          <SubsImage
            subsImgUrl={subsLeftImg || subsRightImg}
            width={300}
            height={220}
            containerClass="dn flex-ns z-1"
          />
        )}

        <SubsMainBody
          subsSize={subsSize}
          subsHeadline={subsHeadline}
          subsCtaText={subsCtaText}
          subsCtaUrl={subsCtaUrl}
          subsIsTextCta={subsIsTextCta === "true"}
          subsTriggerText={subsTriggerText}
          btnColor={btnColor}
          btnHoverColor={btnHoverColor}
          subsMobileImg={subsMobileImg}
          subsSubscriptionLocation={subsSubscriptionLocation}
          subsPricing={subsPricing}
          subsContentType={subsContentType}
          isCancelledOffer={isCancelledOffer}
        />

        {/* desktop right side image */}
        {(subsRightImg || subsLeftImg) && (
          <SubsImage
            subsImgUrl={subsRightImg || subsLeftImg}
            width={300}
            height={220}
            containerClass="dn flex-ns z-1"
          />
        )}
      </div>
    </div>
  );
};

export default InlineSubsModule;
