import React from "react";
import PropTypes from "prop-types";
import "@washingtonpost/tachyons-css/dist/index.css";
import "../sass/app.scss";
import { Favicon } from "@washingtonpost/site-favicons";
import { globalStyles } from "@washingtonpost/wpds-ui-kit";
import Head from "next/head";

export default function AssemblerApp({ Component, pageProps }) {
  globalStyles();
  return (
    <React.Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Favicon {...pageProps} />
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  );
}
AssemblerApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any
};
