import PropTypes from "fusion-prop-types";
import { dividerWeights } from "~/components/features/fronts/utilities/common-front-properties";

export const DiversionsPropTypes = {
  customFields: PropTypes.shape({
    rowSpan: PropTypes.number.tag({
      name: "Row Spans",
      description:
        "If there is too much white space use this to adjust row height",
      defaultValue: 1
    }),
    horizontalDividerWeight: PropTypes.oneOf(Object.keys(dividerWeights)).tag({
      labels: dividerWeights,
      name: "Horizontal divider",
      description:
        "Select the weight of the horizontal divider, which appears below the feature when there is another feature positioned below it",
      defaultValue: "normal"
    })
  })
};
