import React from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";

import get from "lodash.get";
import { useFusionContext, useComponentContext } from "fusion-context";
import { slugify } from "~/components/utilities/string-helper";

import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useSpartanFeature } from "~/src/content";
import { useTableLayoutContext } from "~/shared-components/TableLayoutContext";
import { getHideClasses } from "~/shared-components/chains/top-table/utilities/data-transformation";

import ErrorBoundary from "~/shared-components/ErrorBoundary";

const MobileCard = dynamic(() =>
  import("~/shared-components/story-card/MobileCard")
);
const IllegalPlacementWarning = dynamic(() =>
  import("~/shared-components/Warning").then((m) => m.IllegalPlacementWarning)
);
const DEFAULT_COLUMN_CLASSES =
  "hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans";

const GridItem = ({
  index = 0,
  feedIndex,
  attrs = {},
  style = {},
  className = "",
  overrides = {},
  featureLabel,
  placementRequirements,
  children
}) => {
  const { isAdmin = false, outputType } = useFusionContext() || {};
  const { type } = useComponentContext();
  const layoutCtx = useSandwichLayoutContext() || {};
  const { layoutName } = layoutCtx;
  const { index: chainChildIndex } = useTableLayoutContext();

  const {
    dataAttrs,
    selectors: { styles: adminStyles, overlay },
    interactions: spartanInteractions,
    suppressWarning,
    ref,
    change,
    setOpenMobileCard,
    showMobileCard,
    openMobileCard
  } = useSpartanFeature();

  const { displayName = type } = overrides;

  const dataSpans = {
    "data-mx-span": layoutCtx.layoutVars && layoutCtx.layoutVars["--c-span-mx"],
    "data-lg-span": layoutCtx.layoutVars && layoutCtx.layoutVars["--c-span-lg"],
    "data-md-span": layoutCtx.layoutVars && layoutCtx.layoutVars["--c-span-md"],
    "data-sm-span": layoutCtx.layoutVars && layoutCtx.layoutVars["--c-span-sm"],
    "data-xs-span": layoutCtx.layoutVars && layoutCtx.layoutVars["--c-span-xs"]
  };

  attrs = {
    ...dataAttrs,
    "data-index": `${chainChildIndex}${feedIndex ? `.${feedIndex}` : ""}`,
    "data-admin-id": layoutCtx ? layoutCtx.itemId : undefined,
    "data-feature-name": displayName ? slugify(displayName) : undefined,
    ...dataSpans,
    ...attrs
  };

  style = {
    ...get(layoutCtx, "layoutVars", {}),
    ...style
  };

  const hideClasses = getHideClasses(
    layoutName,
    overrides,
    outputType,
    isAdmin
  );

  const layoutClasses = get(layoutCtx, "layoutClasses", DEFAULT_COLUMN_CLASSES);

  const positionClasses = get(
    layoutCtx,
    `positionClassesArray[${index}]`,
    get(layoutCtx, "positionClasses", "")
  );

  if (showMobileCard && index > 0) return null;

  return (
    <div
      className={`card relative
        ${hideClasses}
        ${layoutClasses}
        ${positionClasses}
        ${className}
      `}
      style={{ ...style, ...adminStyles }}
      {...attrs}
      {...spartanInteractions}
      ref={ref}
    >
      {overlay}
      <ErrorBoundary showErrorMessage={isAdmin} level="error">
        {showMobileCard && openMobileCard !== dataAttrs.id ? (
          <MobileCard
            overrides={overrides}
            isAdmin={isAdmin}
            featureLabel={displayName ? slugify(displayName) : undefined}
            featId={dataAttrs.id}
            setOpenMobileCard={setOpenMobileCard}
            setEntity={change}
          />
        ) : (
          children
        )}
      </ErrorBoundary>
      {isAdmin && !suppressWarning && (
        <IllegalPlacementWarning
          featureLabel={featureLabel}
          placementRequirements={placementRequirements}
          overrides={overrides}
          dataSpans={dataSpans}
        />
      )}
    </div>
  );
};

GridItem.propTypes = {
  index: PropTypes.number,
  feedIndex: PropTypes.number,
  className: PropTypes.string,
  attrs: PropTypes.object,
  style: PropTypes.object,
  overrides: PropTypes.object,
  featureLabel: PropTypes.string,
  placementRequirements: PropTypes.object,
  children: PropTypes.any
};

export default GridItem;
