import {
  ContentConfig,
  ContentCache,
  ResponseCache,
  FetchConfig
} from "../types";
import logger from "../../logger";

let _fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
if (typeof window === "undefined") {
  _fetch = require("node-fetch");
} else {
  _fetch = global.fetch;
}

// Set a fetch timeout below 20 seconds, to match Akamai
const FIFTEEN_SECONDS_IN_MILLIS: number = 15 * 1000;
const fetch = (input: RequestInfo, init?: RequestInit) => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    logger.error("fetch aborted", { input });
    controller.abort();
  }, FIFTEEN_SECONDS_IN_MILLIS);
  return _fetch(input, { ...init, signal: controller.signal })
    .catch((error) => {
      logger.error("fetch failed", { error, input });
      throw error;
    })
    .finally(() => clearTimeout(timeout));
};

export class UpstreamError extends Error {
  url: string;
  status: number;
  statusText: string | null;
  source: string;
  constructor(config: ContentConfig, res: Response) {
    super();
    Object.assign(this, config);
    this.url = res.url;
    this.source = config.source || config.contentService || "none";
    this.status = res.status || 500;
    this.statusText = res.statusText || null;
    this.toString = function () {
      if (!this.source || !this.url) return "unknown fetch error";
      return `Failed to fetch content source: ${this.source}`;
    };
  }
}

export class RedirectError extends Error {
  location: string;
  status: number;
  constructor(location: string) {
    super();
    this.location = location;
    this.status = 301;
  }
}

export const createCacheKey = (config: ContentConfig) => {
  const { source, query, contentService, contentConfigValues } = config;
  return JSON.stringify({
    source: source || contentService,
    query: query || contentConfigValues
  });
};

export const createExternalCacheKey = (config: FetchConfig) => {
  const { endpoint, queryParams, fetchOptions } = config;
  return JSON.stringify({
    endpoint,
    queryParams,
    fetchOptions
  });
};

export const getFetchUrl = (config: ContentConfig) => {
  const {
    source,
    query,
    filter,
    contentService,
    url,
    enableServeStale = true
  } = config;
  const sourceToFetch = source || contentService;
  const baseUrl = url || "http://localhost:8000";

  let fetchUrl = `${baseUrl}/${sourceToFetch}?_website=washpost`;
  if (query) {
    fetchUrl += `&query=${encodeURIComponent(JSON.stringify(query))}`;
  }
  if (filter) {
    fetchUrl += `&filter=${encodeURIComponent(
      // For the filter to be formatted properly we need to strip out all spaces and replace them with a single space.
      filter.replace(/(?:\s+)/g, " ")
    )}`;
  }

  if (enableServeStale) {
    fetchUrl += `&enableServeStale=true`;
  }

  return fetchUrl;
};

export const getExternalFetchUrl = (config: FetchConfig) => {
  const { endpoint, queryParams } = config;
  const baseUrl = endpoint || "http://localhost:8000";

  const url = new URL(baseUrl);
  Object.entries(queryParams || {}).forEach(([key, val]) => {
    url.searchParams.set(key, val);
  });

  return url.href;
};

export const createContentCache = ({
  data,
  url,
  headers
}: {
  data?: ResponseCache;
  url: string;
  headers?: any;
}): ContentCache => {
  headers = {
    ...headers,
    "User-Agent": "WaPoAPI_UA/wapo-site-eng-assembler"
  };
  let promiseCache: { [key: string]: Promise<any> } = {};
  const responseCache = data || {};
  return {
    debug() {
      return {
        pendingSize: Object.keys(promiseCache).length,
        responseSize: Object.keys(responseCache).length
      };
    },
    extract() {
      return responseCache;
    },
    wait() {
      return Promise.all(
        Object.keys(promiseCache).map((k) => {
          return promiseCache[k].then(
            (res) => [k, res],
            // ignore failurs and just return null here.
            (e) => {
              logger.error("", { error: e });
              return [k, null];
            }
          );
        })
      ).then((responses) => {
        responses.forEach((sourceResponses) => {
          const [key, res] = sourceResponses;
          responseCache[key] = res;
        });
        promiseCache = {};
      });
    },
    hasPromises() {
      return Object.keys(promiseCache).length > 0;
    },
    serialize() {
      return JSON.stringify(responseCache);
    },
    getContent(config: ContentConfig) {
      const key = createCacheKey(config);
      const content = responseCache[key];
      return content;
    },
    getContentFromResponseOrPromiseCache(
      key: string,
      updateFromCache: Boolean = true
    ) {
      // if res[key] is null it means it's attempted to make the request in the past but got a non successful response
      // if res[key] is undefined it means it's never made the request for this config
      // else it exists
      const resExists = typeof responseCache[key] !== "undefined";

      if (resExists && updateFromCache) {
        return Promise.resolve(responseCache[key]);
      }

      if (typeof promiseCache[key] !== "undefined") {
        return promiseCache[key];
      }

      return undefined;
    },
    fetchContent(config: ContentConfig, updateFromCache: Boolean = true) {
      const key = createCacheKey(config);
      const cacheValue = this.getContentFromResponseOrPromiseCache(
        key,
        updateFromCache
      );
      if (cacheValue) {
        return cacheValue;
      }

      const fetchConfig = { url, headers, ...config };

      // eslint-disable-next-line no-use-before-define
      promiseCache[key] = fetchContent(fetchConfig);
      return promiseCache[key];
    },
    fetchExternalContent(config: FetchConfig) {
      const { fetchOptions } = config;
      const key = createExternalCacheKey(config);
      const cacheValue = this.getContentFromResponseOrPromiseCache(key, true);
      if (cacheValue) {
        return cacheValue;
      }

      const fetchConfig = {
        ...config,
        fetchOptions: {
          ...fetchOptions,
          headers: { ...headers, ...fetchOptions?.headers }
        }
      };

      // eslint-disable-next-line no-use-before-define
      promiseCache[key] = fetchExternalContent(fetchConfig);
      return promiseCache[key];
    }
  };
};

export function fetchContent(config: ContentConfig) {
  const { source, contentService, transform, headers } = config;
  const sourceToFetch = source || contentService;
  const fetchUrl = getFetchUrl(config);
  return fetch(fetchUrl, {
    method: "GET",
    headers
  })
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(new UpstreamError(config, res));
      }

      // apply transformations if we have them
      if (transform) {
        return res.json().then((json) => {
          return Promise.resolve(transform(json, sourceToFetch));
        });
      }

      return res.json();
    })
    .catch((e) => {
      if (e instanceof UpstreamError) {
        logger.error("FetchContentUpstreamError", {
          error: e
        });
      } else {
        logger.error("FetchContentError", {
          error: e,
          config,
          fetchUrl
        });
      }
      return Promise.resolve(null);
    });
}

export function fetchExternalContent(config: FetchConfig) {
  const { fetchOptions, transform } = config;
  const fetchUrl = getExternalFetchUrl(config);
  return fetch(fetchUrl, fetchOptions)
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(new UpstreamError(config, res));
      }

      // apply transformations if we have them
      if (transform) {
        return res.json().then((json) => {
          return Promise.resolve(transform(json));
        });
      }

      return res.json();
    })
    .catch((e) => {
      logger.error("FetchContentError", {
        error: e,
        config,
        fetchUrl
      });

      return Promise.resolve(null);
    });
}
