import { useFusionContext } from "./context";
import { fetchContent } from "../fusion-client";

const wrapWithContext = (Comp: any) => {
  return function ContextConsumerWrapper(props: any) {
    const context = useFusionContext();
    return <Comp {...props} {...context} />;
  };
};

export function Consumer(ComponentToBeWrapped: any) {
  class WithFetchMixin extends ComponentToBeWrapped {
    constructor(props: any) {
      super(props);
      this.state = this.state || {};
    }

    // eslint-disable-next-line class-methods-use-this
    fetchContent(config: any) {
      const fetches = Object.keys(config).map((k) => {
        return fetchContent(config[k]).then((res) => {
          return {
            k,
            res
          };
        });
      });
      Promise.all(fetches).then((responses) => {
        const hydratedConf: any = {};
        responses.forEach((tuple) => {
          hydratedConf[tuple.k] = tuple.res;
        });
      });
    }
  }
  return wrapWithContext(WithFetchMixin);
}
