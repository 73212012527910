import get from "lodash.get";
import defaults from "~/components/features/fronts/flex-feature/utilities/custom-field-defaults";
import { isImageUrl } from "~/shared-components/story-card/_children/Image.helpers.js";

const legacyMap = {
  "+ Image right 16:9 – Hide blurb": "+ Image right – Hide blurb",
  "+ Image: Display + Force 3x2":
    "+ Image: Display (image above + caption/credit)",
  "+ Image: Display + Force 16x9":
    "+ Image: Display (image above + caption/credit)",
  "+ Image: Lede Display + Force 3x2":
    "+ Image: Lede Display (image below head + caption/credit)",
  "+ Image: Lede Display + Force 16x9":
    "+ Image: Lede Display (image below head + caption/credit)"
};

const mobilePresets = {
  "As is": {
    // NOTE: Intentionally an empty object
  },
  "As is (but not hidden)": {
    // NOTE: Intentionally an empty object
  },
  "+ Image right – Hide blurb": {
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    includeDurationOrLive: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art right of head",
    artSize: "Small",
    artAspectRatio: "1:1",
    wrapText: true,
    slideshowShow: false,
    captionContent: "None",
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true
  },
  "+ Image right": {
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    includeDurationOrLive: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art right of head",
    artSize: "Small",
    artAspectRatio: "1:1",
    wrapText: true,
    slideshowShow: false,
    captionContent: "None",
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit"
  },
  "+ Image: Display (image above + caption/credit)": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artSize: "Full Width",
    captionContent: "Credits only"
  },
  "+ Image: Display (image below byline + caption/credit)": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art below byline",
    artSize: "Full Width",
    captionContent: "Credits only"
  },
  "+ Image: Display (image below byline + caption/credit) — Hide blurb": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art below byline",
    artSize: "Full Width",
    captionContent: "Credits only",
    blurbHide: true
  },
  "+ Image: Display – Hide blurb": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artSize: "Full Width",
    captionContent: "Credits only",
    blurbHide: true
  },
  "+ Image: Display — Hide slideshow": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artSize: "Full Width",
    captionContent: "Credits only",
    slideshowShow: false
  },
  "+ Image: Display — Prefer slideshow": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "3:2",
    artSize: "Full Width",
    captionContent: "Credits only",
    slideshowShow: true
  },
  "+ Image: Display + Large headline": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artSize: "Full Width",
    captionContent: "Credits only",
    headlineSize: "Large"
  },
  "+ Image: Display Feature (image above + caption, centered)": {
    textAlignment: "center",
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artSize: "Full Width",
    captionContent: "Normal caption",
    headlineStyle: "Regular"
  },
  "+ Image: Lede Display (image below head + caption/credit)": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art below head",
    artSize: "Full Width",
    captionContent: "Normal caption"
  },
  "+ Image: Display Special Feature (image below head, for projects)": {
    inlineVideo: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art below head",
    artSize: "Full Width",
    captionContent: "Credits only",
    headlineStyle: "Bold",
    headlineAlignment: "center",
    labelAlignment: "center"
  },
  "+ Large headline": {
    headlineHide: false,
    headlineSize: "Large"
  },
  "– Hide blurb": {
    artSize: "Small",
    blurbHide: true
  },
  "– Hide blurb and image": {
    artHide: true,
    blurbHide: true
  },
  "– Hide blurb and image, force Small headline": {
    artHide: true,
    blurbHide: true,
    headlineSize: "Small"
  },
  "– Hide image only": {
    artHide: true
  },
  "Feed head": {
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    includeDurationOrLive: false,
    alternateArtLinkEnable: false,
    artHide: true,
    headlineHide: false,
    headlineSize: "Tiny",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    liveTickerHide: true
  },
  "Feed head + mug": {
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    includeDurationOrLive: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art right",
    artSize: "Small",
    artAspectRatio: "1:1",
    slideshowShow: false,
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Tiny",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    liveTickerHide: true
  },
  "Modernized lede": {
    headlineHide: false,
    blurbHide: false,
    timestampShow: true,
    actionsShow: "always",
    artHide: false,
    artPosition: "Art below byline",
    cardifyForcedBleedType: "inset",
    captionContent: "Credits only",
    headlineSize: "Large"
  },
  "Modernized Opinion lede": {
    headlineHide: false,
    labelShow: false,
    labelSecondaryShow: true,
    blurbHide: false,
    timestampShow: true,
    actionsShow: "always",
    artHide: false,
    artPosition: "Art below byline",
    cardifyForcedBleedType: "inset",
    captionContent: "Credits only",
    headlineSize: "Large",
    bylineShow: false
  },
  "Secondary off-lede": {
    headlineHide: false,
    blurbHide: false,
    timestampShow: true,
    actionsShow: "always",
    artHide: false,
    artPosition: "Art below byline",
    cardifyForcedBleedType: "inset",
    captionContent: "Credits only",
    headlineSize: "Small"
  },
  Opinion: {
    alternateArtLinkEnable: false,
    artHide: false,
    artOverlayShow: false,
    artPosition: "Art right",
    artSize: "X-Small",
    artAspectRatio: "1:1",
    slideshowShow: false,
    blurbHide: true,
    bylineShow: true,
    bylineShowOpinion: true,
    captionContent: "None",
    headlineAlignment: "inherit",
    headlineHide: false,
    headlineSize: "Extra small",
    headlineStyle: "Regular",
    inlineVideo: false,
    textAlignment: "left",
    timestampShow: false
  },
  "LUF Ticker, Hide Art": {
    artHide: true,
    blurbHide: false,
    liveTickerHide: false,
    liveTickerNumMobileOnly: 2
  },
  "HIDE ENTIRE FEATURE": {
    // NOTE: Intentionally an empty object
  }
};

export default mobilePresets;

/*
 * @param {string} s - The string to check.
 *
 * @returns {boolean} - Whether the string is "As is"-ish.
 */
const isAsIs = (s) => /^As is( \(but not hidden\))?$/.test(s);

/*
 * @param {object} customFields - The customFields
 *
 * @returns {string} - The canonical string of the mobilePreset.
 *
 * NOTE: Uses legacyMap as a layer of protection against name changes
 */
const getMobilePreset = (customFields) =>
  legacyMap[get(customFields, "mobilePreset", "As is")]
    ? legacyMap[customFields.mobilePreset]
    : customFields.mobilePreset;

/*
 * The object returned by this method will have no keys if there are no
 * mobile only custom fields to apply. Otherwise, this object can be merged
 * into the custom fields as appropriate. If the mobilePreset is "As is",
 * it resets it to "Not quite as is". This is needed for Apps as any non-"As is"
 * mobile preset will assure that the feature gets split into two items in
 * the jsonapp feed.
 *
 * @param {object} customFields - The customFields
 *
 * @returns {object} - An object containing mobile only custom fields.
 */
const getMobileOnlyCustomFields = (customFields) => {
  const { artHide, headlineHide, relatedLinksHide, liveTickerHide } =
    customFields;
  const mobileOnlyCustomFields = Object.keys(customFields)
    .filter((mobileOnlyKey) => /.+MobileOnly$/.test(mobileOnlyKey))
    .reduce((acc, mobileOnlyKey) => {
      const key = mobileOnlyKey.replace(/MobileOnly$/, "");

      const value = customFields[key] ?? defaults[key];
      const mobileOnlyValue = customFields[mobileOnlyKey];
      // NOTE: If mobile value equals normal value or default mobile value, then don't add it
      if (
        // NOTE: for number fields, the corresonding mobile only field is a string so that
        // blank can mean inherit from desktop. Assembler won't let you set a number field
        // to blank. Hence, only using eqeq here so that, e.g. "3" == 3 will return true
        mobileOnlyValue == value || // eslint-disable-line eqeqeq
        mobileOnlyValue === defaults[mobileOnlyKey]
      )
        return acc;

      if (/^alternateArt$/.test(key)) {
        if (!artHide && isImageUrl(mobileOnlyValue)) {
          acc[key] = mobileOnlyValue;
          // NOTE: Also de-activate video and coverArt but NOT live graphics
          acc.inlineVideo = false;
          acc.coverArtUrl = "";
          // acc.liveGraphicsHide = true;
        }
      } else if (/^artAspectRatio$/.test(key)) {
        if (!artHide) acc[key] = mobileOnlyValue;
      } else if (/^headline/.test(key)) {
        if (!headlineHide) acc[key] = mobileOnlyValue;
      } else if (/^relatedLinksNum/.test(key)) {
        // START: only obey mobile value if it is less than regular value
        // NOTE: jsonapp will "split" if MobileOnly is activated. That's why there are
        // checks here for the hide/show props to prevent unnecessarily "split"
        if (
          !relatedLinksHide &&
          /^\d+$/.test(mobileOnlyValue) &&
          mobileOnlyValue < value
        )
          acc[key] = Number.parseInt(mobileOnlyValue, 10);
      } else if (/^liveTickerNum/.test(key)) {
        if (
          !liveTickerHide &&
          /^\d+$/.test(mobileOnlyValue) &&
          mobileOnlyValue < value
        )
          acc[key] = Number.parseInt(mobileOnlyValue, 10);
      } else if (/^limit/.test(key)) {
        // NOTE: if there is no hide/show key, this regexp can be extended to cover them
        if (/^\d+$/.test(mobileOnlyValue) && mobileOnlyValue < value)
          acc[key] = Number.parseInt(mobileOnlyValue, 10);
        // END: only obey mobile value if it is less than regular value
      } else {
        acc[key] = mobileOnlyValue;
      }
      return acc;
    }, {});

  if (
    isAsIs(getMobilePreset(customFields)) &&
    Object.keys(mobileOnlyCustomFields).length
  ) {
    mobileOnlyCustomFields.mobilePreset = "Not quite as is";
  }

  return mobileOnlyCustomFields;
};

/*
 * With mobile only custom fields, even "As is" features can end up
 * not "As is". That's why when the mobilePreset is "As is" this code
 * genertates the mobile only custom fields, then interrogates that object
 * to see if there are any mobile presets. Basically, if the mobilePreset
 * started off as "As is" but it has mobile only custom fields, the mobilePreset
 * in the object of interest will be "Not quite as is"
 *
 * @param {object} customFields - The customFields
 *
 * @returns {boolean} - Whether or not the mobilePreset is "As is".
 */
export const isMobilePresetAsIs = (customFields) => {
  let { mobilePreset } = customFields;
  if (!isAsIs(mobilePreset)) return false;
  ({ mobilePreset } = getMobileOnlyCustomFields(customFields));
  return !/Not quite as is/.test(mobilePreset);
};

/**
 * Using the chainCtx, this determines if mobile or desktop ordering is active. If mobile ordering,
 * then merge i) any mobile only custom fields and ii) mobile presets into custom fields and return them.
 * It makes sense that mobile ordering and mobile presets are used together and likewise for desktop
 * ordering/presets.
 *
 * @param {object} customFields - The customFields
 * @param {object} chainCtx - The chainCtx that has the useDesktopOrdering flag. Using mobile presets
 *    is tied to !useDesktopOrdering which can be thought of as useMobileOrdering
 *
 * @returns {object} - customFields untouched or with mobile only custom fields and mobile presets merged in.
 */
export const applyMobilePresets = ({ customFields = {}, chainCtx = {} }) => {
  // NOTE: Defaulting to true, means desktop ordering and, hence, normal presets are the default
  const useDesktopOrdering =
    chainCtx.useDesktopOrdering !== undefined
      ? chainCtx.useDesktopOrdering
      : true;

  if (useDesktopOrdering) return customFields;

  // NOTE: Harvest mobilePreset value from customFields.
  const mobilePreset = getMobilePreset(customFields);

  const mobileEnhancedCustomFields = {
    ...customFields,
    ...mobilePresets[mobilePreset]
  };

  // NOTE: Harvest mobileOnly custom fields and apply them (later)
  const mobileOnlyCustomFields = getMobileOnlyCustomFields(
    mobileEnhancedCustomFields
  );

  return {
    ...mobileEnhancedCustomFields,
    ...mobileOnlyCustomFields
  };
};
