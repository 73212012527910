import { EntityRef } from "src/types";

// NOTE: Important for deriving the curation index.
// If a new table zone is added, this must be updated, too
export const colsToNumMap: { [key: string]: number } = {
  Left: 0,
  Main: 1,
  Right: 2,
  Bottom: 3
};

export const tableKeys = [
  "table0LeftIds",
  "table1LeftIds",
  "table1MainIds",
  "table1RightIds",
  "table1BottomIds",
  "table2LeftIds",
  "table2MainIds",
  "table2RightIds",
  "table2BottomIds",
  "table3LeftIds",
  "table3MainIds",
  "table3RightIds",
  "table3BottomIds",
  "table4LeftIds",
  "table4MainIds",
  "table4RightIds",
  "table4BottomIds",
  "table5LeftIds",
  "table5MainIds",
  "table5RightIds",
  "table5BottomIds",
  "table6LeftIds",
  "table6MainIds",
  "table6RightIds",
  "table6BottomIds",
  "table7LeftIds",
  "table7MainIds",
  "table7RightIds",
  "table7BottomIds",
  "table8LeftIds",
  "table8MainIds",
  "table8RightIds",
  "table8BottomIds",
  "tableAd1LeftIds",
  "table9LeftIds",
  "table9MainIds",
  "table9RightIds",
  "table9BottomIds",
  "unassigned"
];

export const getUniqueTableKey = (key: any) =>
  `${key.replace("_uniqueIds", "")}_uniqueIds`;

// Should only be used when mapping a document without the table column id array to have the id array
// also used when cloning a chain and children
export const mapTableColIdsToTableColObjectIds = (chain: any) => {
  const customFields = chain.props.customFields;
  Object.keys(customFields).forEach((key) => {
    const uniqueIdsKey = getUniqueTableKey(key);
    if (tableKeys.includes(key)) {
      // @ts-ignore
      customFields[uniqueIdsKey] = customFields[key]
        .split(",")
        .filter((s: string) => s)
        .flatMap((id: string) => {
          const index = parseInt(id, 10);
          const chainId = chain.children[index - 1]?.id;
          return chainId ? [chainId] : [];
        });
    }
  });

  return chain;
};

// This is the main fn that gives fusion chain child data in a way it can ingest
export const mapTableColObjIdsToTableColIds = (
  chain: any,
  defaultCustomFields?: any
) => {
  const customFields = defaultCustomFields || chain?.props?.customFields;
  if (!customFields || !chain?.children?.length) return customFields;

  // Enforce that there is only one allocated child per tableCol field
  const childIds = (chain?.children || []).map((c: EntityRef) => c.id);
  Object.keys(customFields).forEach((uniqueIdsKey) => {
    const key = uniqueIdsKey.replace("_uniqueIds", "");
    if (tableKeys.includes(key) && uniqueIdsKey.includes("_uniqueIds")) {
      // Set `table1LeftIds` (which is really table1LeftIndices) from `table1LeftIds_uniqueIds`
      customFields[key] = (customFields[uniqueIdsKey] || [])
        .map((id: string) => {
          if (!childIds.includes(id)) {
            // That means this child id has been assigned to 2 columns
            customFields[uniqueIdsKey] = customFields[uniqueIdsKey].filter(
              (colChildId: string) => colChildId !== id
            );
            return null;
          }
          childIds.splice(childIds.indexOf(id), 1);

          const index = chain?.children?.findIndex((c: any) => c.id === id);
          return index + 1;
        })
        .filter((c: number | null) => c !== null)
        .join(",");
    }
  });

  return customFields;
};

// This is our big one, maps all chains to have correct fusion data
export const mapChainsIfNeeded = (state: { [k: string]: any }) => {
  Object.entries(state).forEach(([key, val]) => {
    if (key.indexOf(`renderable-chains`) > -1) {
      mapTableColObjIdsToTableColIds(val);
    }
  });

  return state;
};
