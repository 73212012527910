import React, { createContext, ReactNode, useContext } from "react";
import { AkamaiProvider, useAkamaiExperiment } from "./AkamaiContext";

import {
  ExperimentsContextInterface,
  KeyValAny,
  KeyValNum,
  LocalExperimentInterface,
  OverallExperimentsInterface
} from "../types";

const ExperimentsContext = createContext<ExperimentsContextInterface>({
  getVariantGroup: (_id: string) => undefined,
  getAllVariantGroups: () => ({}),
  akamaiTestId: null
});

/**
 * This is rendered by the global experiments provider
 * Does extra processing to testConfig data
 */
const LocalExperimentsProvider: React.FC<{
  overrideTestData: KeyValNum;
  testConfig: KeyValAny;
  children?: React.ReactNode;
}> = ({ overrideTestData, testConfig, children }) => {
  const akamaiTestId =
    Object.keys(testConfig).find((key) => {
      return /akamai/i.test(testConfig[key].type);
    }) || "akamai";

  const akamaiVariant = useAkamaiExperiment();

  const {
    variant2Key,
    variant3Key,
    variant3: akamaiVariant2
  } = testConfig[akamaiTestId] || {};
  // Defaults to control -- is only variant if url param exactly matches
  // input variant2Key OR variant3Key
  const getAkamaiVariant = () => {
    if (!akamaiVariant) return 0;
    if (variant2Key && akamaiVariant === variant2Key) return 1;
    if (variant3Key && akamaiVariant === variant3Key) return 2;
    return 0;
  };
  // this grabs the real variant 3 id that is nested within the variant experiment object
  const realAkamaiTestId =
    variant3Key && akamaiVariant === variant3Key
      ? akamaiVariant2
      : akamaiTestId;

  const allTestVariants: KeyValNum = {
    [realAkamaiTestId]: getAkamaiVariant(),
    ...(overrideTestData || {})
  };

  /**
   * We can access the variant info based on test id
   * or test slug. Slug is needed for in-the-code changes
   * in wp-fusion so that the optimizeId and testId can
   * change / be updated without a new deploy
   *
   * e.g. test id / optimize id used for staging env
   *      then switch over to diff ids for prod env
   */
  const getVariantGroup = React.useCallback(
    (testId: string) => {
      const slugEntry = Object.entries(testConfig || {}).find(
        ([_, { slug }]) => slug === testId
      );
      let id = slugEntry ? slugEntry[0] : testId;

      const { variant3Key } = testConfig[akamaiTestId] || {};

      if (variant3Key && akamaiVariant) {
        id = variant3Key && akamaiVariant === variant3Key ? akamaiVariant2 : id;
      }

      // For now, default to 0 for control group if test id
      // does not exist
      return id ? allTestVariants[id] || 0 : 0;
    },
    [allTestVariants, testConfig]
  );

  const context = {
    getVariantGroup,
    getAllVariantGroups: () => ({ ...allTestVariants }),
    akamaiTestId
  };

  return (
    <ExperimentsContext.Provider value={context}>
      {children}
    </ExperimentsContext.Provider>
  );
};

/**
 * Wrap your entire application root in this provider to gain
 * access to the experiment / variant hooks
 *
 * @param {string} requestUri The raw page url path to parse query params from (next router.asPath)
 * @param {object} testConfig An object where the keys are testIds and the value contain whether or not is akamai
 * @param {object} overrideTestData An object to force / overwrite variant groups. Keys are test ids, vals are A/B groups
 * @param {node}   children The react children
 */
const defaultProps = { requestUri: "", testConfig: {}, overrideTestData: {} };
const ExperimentsProvider: React.FC<OverallExperimentsInterface> = (props) => {
  const {
    requestUri = defaultProps.requestUri,
    testConfig = defaultProps.testConfig,
    overrideTestData = defaultProps.overrideTestData,
    children
  } = props || defaultProps;
  return (
    <AkamaiProvider requestUri={requestUri}>
      <LocalExperimentsProvider
        testConfig={testConfig}
        overrideTestData={overrideTestData}
      >
        {children}
      </LocalExperimentsProvider>
    </AkamaiProvider>
  );
};

/**
 *
 * @returns {{ getVariantGroup (id: string) => 1 | 0 , getAllVariantGroups () => string[] }}
 */
const useExperimentsProvider = () => useContext(ExperimentsContext);

/**
 * Returns variant group (0 for A / control, 1 for B / variant) for given test id
 * @param {string} testId The testId to grab the variant group for
 * @returns {number} 0 or 1
 */
const useExperiment = (testId: string) => {
  const { getVariantGroup } = useExperimentsProvider();
  return getVariantGroup(testId);
};

/**
 * Returns bool for if in variant A / control group
 * @param {string} testId The testId to grab the variant group for
 * @returns {bool} If variant group is 0
 */
const useIsVariantA = (testId: string) => {
  const { getVariantGroup } = useExperimentsProvider();
  const varaintGroup = getVariantGroup(testId);
  return varaintGroup === 0;
};

/**
 * Returns bool for if in variant B / variant group
 * @param {string} testId The testId to grab the variant group for
 * @returns {bool} If variant group is 1
 */
const useIsVariantB = (testId: string) => {
  const { getVariantGroup } = useExperimentsProvider();
  const variantGroup = getVariantGroup(testId);
  return variantGroup === 1;
};

/**
 * Returns bool for if in variant C / variant group
 * @param {string} testId The testId to grab the variant group for
 * @returns {bool} If variant group is 2
 */
const useIsVariantC = (testId: string) => {
  const { getVariantGroup } = useExperimentsProvider();
  const variantGroup = getVariantGroup(testId);
  return variantGroup === 2;
};

const getValidVariantKeys = (entity: any, activeExperimentIds: string[]) => {
  const entityVariants = entity?.props?.variants || {};
  const variantTestIds = Object.keys(entityVariants);
  return variantTestIds.filter((key) => activeExperimentIds.includes(key));
};

const useCreateEntityVariantGetter = () => {
  const { getAllVariantGroups, akamaiTestId } = useExperimentsProvider();
  const variantGroups = getAllVariantGroups();

  // return pure fn
  return (entity: any) => {
    const { variants, ...props } = entity?.props || {};
    const validKeys = getValidVariantKeys(entity, Object.keys(variantGroups));
    if (!variants || !validKeys.length) {
      return entity;
    }

    // Filter variants by the ones that are live now
    const variantBKeys = validKeys.length
      ? validKeys.filter((key) => variantGroups[key] !== 0)
      : [];
    if (!variantBKeys.length) return entity;

    // If an entity has multiple variants in different tests that are both live,
    // somebody messed up. Prioritize akamai, otherwise just grab the first one
    const hasAkamaiVariant =
      akamaiTestId && variantBKeys.includes(akamaiTestId);
    const variantKey = hasAkamaiVariant ? akamaiTestId : variantBKeys[0];

    const { props: variantProps, children } = variants[variantKey];

    // custom fields are combined, overwritten if applicable by variant
    return {
      ...entity,
      props: {
        ...props,
        customFields: { ...props.customFields, ...variantProps.customFields }
      },
      // children are taken from variant, full override of default
      children
    };
  };
};

/**
 * Small context that wraps just the children of an <Experiment />
 */
const VariantExperimentContext = createContext<LocalExperimentInterface>({
  variantGroup: undefined
});
/**
 * React component that wraps any conditional children only to be
 * rendered in the control group
 *
 * used like so:
 * <Experiment id={'lafakjsdhfasdf}>
 *    <VariantA>I am the control</VariantA>
 *    <VariantB>I am variant 1</VariantB>
 *    <VariantC>I am variant 2</VariantC>
 * </Experiment>
 */

// variant 1
const VariantA: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { variantGroup } = useContext(VariantExperimentContext);
  return <>{variantGroup === 0 ? children : null}</>;
};

// variant 2
const VariantB: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { variantGroup } = useContext(VariantExperimentContext);
  return <>{variantGroup === 1 ? children : null}</>;
};

// variant 3
const VariantC: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { variantGroup } = useContext(VariantExperimentContext);
  return <>{variantGroup === 2 ? children : null}</>;
};

const VariantX: React.FC<{ group: string; children?: ReactNode }> = ({
  group,
  children
}) => {
  const { variantGroup } = useContext(VariantExperimentContext);
  return <>{`${variantGroup}` === group ? children : null}</>;
};

const Experiment: React.FC<{ id: string; children?: ReactNode }> = ({
  id,
  children
}) => {
  const { getVariantGroup } = useExperimentsProvider();
  const variantGroup = getVariantGroup(id);
  const context = {
    id: id || null,
    variantGroup
  };
  return (
    <VariantExperimentContext.Provider value={context}>
      {children}
    </VariantExperimentContext.Provider>
  );
};

export {
  ExperimentsProvider,
  Experiment,
  VariantX,
  VariantA,
  VariantB,
  VariantC,
  useIsVariantA,
  useIsVariantB,
  useIsVariantC,
  useExperiment,
  useExperimentsProvider,
  useCreateEntityVariantGetter
};
