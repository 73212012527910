export function objectToId({
  id,
  props = {},
  collection
}: {
  id?: string;
  props?: any;
  collection?: string;
}): string {
  if (id === "root") {
    return id;
  }
  const prefix = `renderable-${collection}`;
  let properId = id || props.id;
  if (typeof properId !== "string") {
    properId = `${properId}`;
  }
  if (properId.startsWith("renderable")) {
    return properId;
  }
  return `${prefix}-${properId || props.id}`;
}
