import React from "react";
import PropTypes from "prop-types";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";

import { Byline, authorShape } from "./Byline";
import { Timestamp } from "./Timestamp";

// utils
import getResizedUrl from "~/components/core/elements/image/resize-url";
import { isTransparencyLabel } from "~/shared-components/story-card/_children/Label.helpers";
import { getForYouChildAttrs } from "../../utilities/for-you";

export const Headline = styled("h2", {
  fontFamily: theme.fonts.headline,
  fontSize: theme.sizes["125"],
  fontWeight: theme.fontWeights.bold,
  textAlign: "left",
  color: theme.colors.gray40,
  lineHeight: theme.lineHeights.headline,
  paddingBottom: theme.space["075"] // 12px
});

export const HeadlineWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100vw"
});

export const Sigline = styled("span", {
  fontFamily: theme.fonts.meta,
  fontSize: theme.sizes["087"],
  fontWeight: theme.fontWeights.light,
  textAlign: "left",
  color: theme.colors.accessible,
  "& a": {
    color: theme.colors.accessible
  }
});

export const Label = styled("div", {
  marginBottom: theme.space["025"], // 4px
  fontFamily: theme.fonts.subhead,
  fontSize: theme.sizes["087"],
  textAlign: "left",
  letterSpacing: "0.1px"
});

export const TransparencyLabel = styled("a", {
  fontWeight: theme.fontWeights.bold,
  color: theme.colors.primary,
  marginRight: theme.sizes["050"],
  variants: {
    isOpinion: {
      true: {
        textDecoration: "underline",
        textDecorationColor: theme.colors.spark100,
        textDecorationThickness: "1.25px",
        textDederlineOffset: "1px",
        "&:hover": {
          textDecorationColor: theme.colors.gray20
        }
      }
    }
  }
});

export const BylineLabel = styled("span", {
  display: "inline-block",
  color: theme.colors.accessible
});

export const CardContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.space["075"], // 12px
  padding: 0,
  minHeight: "100px",
  a: {
    color: "inherit"
  }
});

export const ImageWrapper = styled("a", {
  display: "flex",
  backgroundSize: "cover",
  backgroundColor: theme.colors.gray600,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  objectFit: "cover",
  "& img": {
    height: "100px",
    display: "flex",
    minWidth: "100px"
  }
});

/**
 * A Multi-view story card with:
 * - thumbnail art
 * - smaller headline
 *
 * @param {object} params.item a story item
 */
const ThumbnailCard = ({ item }) => {
  const headline = item?.headlines?.basic;
  const url = item?.fusion_additions?.links_to_use?.basic;
  const imageUrl = item?.fusion_additions?.promo_image?.url;
  const label = item?.label?.transparency?.text;
  const showLabel = isTransparencyLabel(label);
  const isOpinion = /^Opinion$/.test(label);
  const displaydate = item?.display_date;
  const firstdisplaydate = item?.additional_properties?.first_display_date;
  const authors = item?.credits?.by;
  const attrForYou = { ...getForYouChildAttrs(item) };

  return (
    <CardContent className="card stream-item" {...attrForYou}>
      <HeadlineWrapper>
        {showLabel && (
          <Label>
            <TransparencyLabel href={url} isOpinion={isOpinion}>
              {label}
            </TransparencyLabel>
            <BylineLabel>
              <Byline authors={authors} />
            </BylineLabel>
          </Label>
        )}
        <Headline>
          <a href={url}>{headline}</a>
        </Headline>
        <Sigline>
          <Timestamp
            isSigline={false}
            time={displaydate}
            beforeTime={firstdisplaydate}
          />
        </Sigline>
      </HeadlineWrapper>
      {imageUrl && (
        <ImageWrapper href={url}>
          <img src={getResizedUrl(imageUrl, { w: 120, h: 120 })} />
        </ImageWrapper>
      )}
    </CardContent>
  );
};

ThumbnailCard.propTypes = {
  item: PropTypes.shape({
    additional_properties: PropTypes.shape({
      first_display_date: PropTypes.string,
      for_you: PropTypes.shape({
        request_id: PropTypes.string,
        article_id: PropTypes.string
      })
    }),
    headlines: PropTypes.shape({
      basic: PropTypes.string
    }),
    label: PropTypes.shape({
      transparency: PropTypes.shape({
        text: PropTypes.string
      })
    }),
    display_date: PropTypes.string,
    credits: PropTypes.shape({
      by: PropTypes.arrayOf(authorShape)
    }),
    fusion_additions: PropTypes.shape({
      links_to_use: PropTypes.shape({
        basic: PropTypes.string
      }),
      promo_image: PropTypes.shape({
        url: PropTypes.string
      })
    })
  })
};

export default ThumbnailCard;
