import React, { ReactNode } from "react";
import {
  ComponentProvider,
  useAppContext,
  useComponentMap,
  FeatureContext,
  ChainContext
} from "../content";
import { useCreateEntityVariantGetter } from "../experiments";
import { mapTableColObjIdsToTableColIds, objectToId } from "../utils";
import { RenderableProps } from "../types";

const SpartanFeatureProvider: React.FC<{ value: any; children: ReactNode }> = ({
  children,
  value
}) => {
  const selectors = {
    interactiveState: {},
    styles: {},
    OverlayBar: () => null,
    overlay: null
  };
  const interactions = {};
  const ref = React.useRef();
  return (
    <FeatureContext.Provider value={{ interactions, selectors, ref, ...value }}>
      {children}
    </FeatureContext.Provider>
  );
};

const ChainProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const selectors = {
    interactiveState: {},
    styles: {},
    OverlayBar: () => null,
    overlay: null,
    ChainColumnDropTarget: () => null
  };
  const interactions = {};
  return (
    <ChainContext.Provider
      value={{ interactions, selectors, showHelpers: false, dataAttrs: {} }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export const Feature: React.FC<{
  props: { props: RenderableProps; type?: string };
  context: any;
}> = ({ props, context }) => {
  const Component = useComponentMap(props?.type);

  return (
    <SpartanFeatureProvider value={props}>
      <ComponentProvider value={props.props}>
        <Component {...props.props} context={context} />
      </ComponentProvider>
    </SpartanFeatureProvider>
  );
};

export const Chain = (chainProps: {
  type?: string;
  props: any;
  children: any[];
}) => {
  const { props, children = [] } = chainProps;
  const context = useAppContext();
  const getControlOrVariant = useCreateEntityVariantGetter();
  const memoedProps = React.useMemo(() => {
    return {
      ...chainProps,
      /**
       * Just in case duplicate feature ids made it into the published doc,
       * we run this function to finalize mapping / de-duping of feature ids
       * within a chain, like we do in /src/admin/components/AdminRenderable -> Chain
       */
      customFields: mapTableColObjIdsToTableColIds(
        chainProps,
        JSON.parse(JSON.stringify(props?.customFields))
      ),
      children: children.map((feature) => {
        return (
          <Feature
            context={context}
            key={objectToId(feature)}
            props={feature}
          />
        );
      }),
      childProps: children.map((c) => getControlOrVariant(c)?.props),
      context
    };
  }, [chainProps, context, children]);

  const Comp = useComponentMap(chainProps?.type);
  return (
    <ChainProvider>
      <ComponentProvider value={props}>
        <Comp
          {...memoedProps.props}
          context={memoedProps.context}
          children={memoedProps.children}
          childProps={memoedProps.childProps}
        />
      </ComponentProvider>
    </ChainProvider>
  );
};
export const Section = ({
  section,
  getEntity
}: {
  children: any;
  section: any;
  getEntity: any;
}) => {
  const context = useAppContext();
  const getControlOrVariant = useCreateEntityVariantGetter();
  const { children: originalChildren = [] } = getControlOrVariant(section);

  const Comp = useComponentMap("section");
  const id = objectToId(section);

  const renderChildren = (children: { id: string }[]) =>
    children.map((child: { id: string }) => {
      const renderable = getControlOrVariant(getEntity(child?.id));
      const { collection, props } = renderable || {};
      if (collection === "chains") {
        return (
          <Chain
            key={objectToId(props)}
            {...renderable}
            children={renderable.children
              .map((c: { id: string }) => getControlOrVariant(getEntity(c?.id)))
              .filter((c: { id: string }) => c)}
          />
        );
      }
      if (collection === "features") {
        return (
          <Feature
            key={objectToId(props)}
            props={renderable}
            context={context}
          />
        );
      }
      return null;
    });

  if (!Comp) {
    return renderChildren(originalChildren);
  } else {
    return (
      <Comp
        id={id}
        renderChildren={renderChildren}
        originalChildren={originalChildren}
      />
    );
  }
};
