import React from "react";
import {
  SiteRootProps,
  ContentRoot,
  ComponentMapContext,
  useComponentMap
} from "../content";
import { ExperimentsProvider } from "../experiments";
import { DocumentNode } from "../types";
import { objectToId } from "../utils";
import { Section } from "./Renderable";

const noop = () => null;
export const AssemblerRoot = noop;

const Layout: React.FC<{
  tree: { [k: string]: DocumentNode };
  getEntity: (id: string) => DocumentNode;
  isAdmin: boolean;
}> = ({ tree, getEntity }) => {
  const SectionWrapper = useComponentMap("wrapper");
  const ConcreteLayout = useComponentMap(tree?.root?.type);
  const sections = tree?.root?.children!.map((s) => (
    <SectionWrapper
      key={s.id}
      section={getEntity(objectToId(s))}
      getEntity={getEntity}
    />
  ));
  return <ConcreteLayout>{sections}</ConcreteLayout>;
};

export const SiteRoot: React.FC<SiteRootProps> = ({
  components,
  tree,
  contentCache,
  additionalContext = {},
  requestUri
}) => {
  /**
   * Fully launched & stable for years, therefore oldRendering code removed (30 Aug 2024)
   *
   * We used to return a denorm'd json blob with each entity / children pieced together
   * For A/B testing, we had to change this because an entity's children could be
   * different based on the variant.
   *
   * Now the "new" rendering object is the normalized structure ({[entityId]: {...}})
   * which lets the code piece together the children / props based on the variant props
   *
   * Might have been overkill to create an entirely new Layout component, but
   * better safe than sorry
   */
  const getEntity = (id: string) => tree?.[id];
  const LayoutComponent = Layout;
  const SectionComponent = Section;
  return (
    <ContentRoot cache={contentCache} additionalContext={additionalContext}>
      <ComponentMapContext.Provider
        value={{
          ...components,
          wrapper: { Component: SectionComponent, fields: null }
        }}
      >
        <ExperimentsProvider
          requestUri={requestUri}
          testConfig={tree?.root?.props?.experiments || {}}
          overrideTestData={{}}
        >
          <LayoutComponent tree={tree} isAdmin={false} getEntity={getEntity} />
        </ExperimentsProvider>
      </ComponentMapContext.Provider>
    </ContentRoot>
  );
};
