import React, { createContext, useContext } from "react";
import { AkamaiExpInterface, KeyValNum } from "../types";

/**
 * Get all query parameters from provided URL
 */
export const getQueryParams = (url: string = "") => {
  const vars: KeyValNum = {};

  // Grab all instances of ?[x]=[y] or &[x]=[y] capturing 'x' and 'y' into groups
  const regex = /[?&]+([^=&]+)=([^&]*)/gi;
  // @ts-ignore
  url.replace(regex, (m, key, val) => {
    vars[key] = (val || "").trim();
  });
  return vars;
};

/**
 * Hook for determining the current variant for a server-side experiment
 * Reads in the ?variant= query string on the server
 */
const useAkamaiVariant = (requestUri: string = "") => {
  const { variant } = getQueryParams(requestUri) || undefined;
  return variant;
};

const AkamaiContext = createContext<{ variant: number | undefined }>({
  variant: undefined
});

const AkamaiProvider: React.FC<AkamaiExpInterface> = ({
  requestUri,
  children
}) => {
  const variant = useAkamaiVariant(requestUri);

  if (typeof window !== "undefined") {
    // set the variant on the window
    // @ts-ignore
    window.akamaiVariant = variant;
  }

  return (
    <AkamaiContext.Provider value={{ variant }}>
      {children}
    </AkamaiContext.Provider>
  );
};

const { Consumer: AkamaiConsumer } = AkamaiContext;

function useAkamaiExperiment() {
  return useContext(AkamaiContext)?.variant;
}

export const AkamaiActiveExperimentIds = {};

export { AkamaiContext, AkamaiConsumer, AkamaiProvider, useAkamaiExperiment };
